import zhLocale from "element-ui/lib/locale/lang/zh-CN";
const zh = {
  // 登录
  login: {
    title: "账号登录",
    userPlaceholder: "请输入用户名",
    passPlaceholder: "请输入密码",
    captChaPlaceholder: "请输入验证码",
    userMessage: "用户名不能为空",
    passMessage: "密码不能为空",
    captChaMessage: "验证码不能为空",
    logIn: "登录",
    infoError: "用户名或密码错误",
    codeLapse: "验证码已失效，请刷新验证码重试",
    codeError: "验证码错误",
    loginFailure: "系统登录失败",
    loginException: "系统登录异常",
    loginTips: "正在登录中，请勿重复操作",
    replaceCode: "换一个",
    tokenExpire: "对不起，当前登录已过期，请重新登录",
  },
  // 修改初始密码
  initialPwd: {
    title: "修改初始密码",
    changePwd: "修改密码",
    accountNum: "账号",
    oldPassword: "旧密码",
    password: "新密码",
    confirmPwd: "确认密码",
    placeholderOne: "请输入新密码",
    placeholderTwo: "请输入确认密码",
    placeholderThree: "请输入旧密码",
    newTip: "新密码不能为空",
    newFormat: "新密码为6~16位字母和数字组合",
    passTip: "确认密码不能为空",
    oldTip: "旧密码不能为空",
    determine: "确定",
    reset: "重置",
    tipsOne: "密码修改成功,将在",
    tipsTwo: "秒后返回登录界面，请重新登录！",
    login: "立即登录",
  },
  header: {
    title: "福田汽车维修专用工具管理系统",
    fuelVehicle: "燃油车",
    newEnergy: "新能源",
    search: "搜索",
    bulletin: "技术通告",
    notice: "系统公告",
    feedback: "我要反馈",
    feedbackRecords: "反馈记录",
    helpManual: "帮助手册",
    collect: "我的收藏",
    exit: "退出",
    en: "English",
    cn: "中文",
    brand: "福田汽车",
  },
  basicInfo: {
    title: "基本信息",
    account: "账号名",
    name: "名称",
    gender: "性别",
    male: "男",
    female: "女",
    contacts: "联系人",
    contactPlaceholder: "请输入联系人",
    telephone: "电话",
    telePlaceholder: "请输入电话",
    address: "地址",
    addressPlaceholder: "请输入地址",
    mailbox: "邮箱",
    mailPlaceholder: "请输入邮箱",
    mailboxTips: "请输入正确的邮箱",
    telephoneTips: "请输入正确的电话",
  },
  button: {
    query: "查询",
    reset: "重置",
    delete: "删除",
    cancel: "取消",
    confirm: "确认",
    submit: "提交",
    submitModify: "提交修改",
    search: "搜索",
    previousPage: "上一页",
    nextPage: "下一页",
    close: "关闭",
    feedback: "我要反馈",
    download: "下载",
    preview: "预览",
    upload: "点击或拖拽上传",
    batchDelete: "批量删除",
    leftRotation: "左旋转",
    rightRotation: "右旋转",
    uploadTip:
      "支持上传 jpg, png, mp3, mp4, xls, xlsx, doc, docx, zip格式的文件，且文件数量不超过5个，单个文件大小不能超过100M",
    know: "知道了",
  },
  table: {
    serialNum: "序号",
    Hits: "点击量",
    releaseTime: "发布时间",
    serviceCode: "服务店代码",
    serviceName: "服务店名称",
    questionerName: "提问人姓名",
    callerNumber: "提问人电话",
    questionType: "问题分类",
    problemStatus: "问题状态",
    problemTypes: "问题类型",
    questionTopic: "问题主题",
    questionTime: "提问时间",
    firstTime: "首次回复时间",
    problemDesc: "问题描述",
    accessory: "附件",
    directory: "目录",
    notes: "备注",
    manualType: "手册类型",
    operate: "操作",
  },
  search: {
    title: "搜索结果",
    vehicleSeries: "车系",
    vehicleType: "车型",
    vehicleCode: "整车编号",
    seriesPlaceholder: "请选择车系",
    typePlaceholder: "请选择车型",
    codePlaceholder: "请选择整车编号",
    searchPlaceholder: "请选择或输入搜索关键词",
    searchTotal: "共找到相匹配的内容",
    totalUnit: "条",
    noTipOne: "没有找到与",
    noTipTwo: "相匹配的内容",
  },
  identifying: {
    unread: "未读",
    urgent: "紧急问题",
    loadTips: "正在加载中...",
    deleteTip: "确定删除相关信息？",
    removeFile: "确定移除选择文件？",
    loginTip: "请重新登录",
    browsingHistory: "最近浏览记录",
    noData: "暂无数据",
    pwsTips: "密码修改成功,将在",
    pwsTipsTwo: "秒后返回登录界面，请重新登录！",
    length: "最多不超过100个字符",
    historicalReply: "历史回复",
    Respondent: "回复",
    recoveryTime: "回复时间",
    probe: "追问",
    consult: "在线咨询",
    contact: "联系客服",
    hotLine: "服务热线",
    fileLimit: "上传文件不能超过 100M！",
    fileTip: "当前限制最多可上传5个文件",
    uploadType: "不支持同文件名上传",
    imgPreview: "点击图片放大查看",
    noReply: "暂无回复",
    errorPrompt: "系统开小差了...",
  },
  navTab: {
    tabTitle: "首页",
    searchResult: "搜索结果",
    VRRetrieval: "VR检索",
    helpManual: "帮助手册",
    closeOther: "关闭其他",
    closeAll: "关闭所有",
    more: "更多",
  },
  // 反馈
  feedback: {
    submit: "正在处理中...",
  },
  successTips: {
    submit: "提交成功",
    modified: "修改成功",
    save: "保存成功",
    operation: "操作成功",
    delete: "删除成功",
    upload: "上传成功",
  },
  partsDetail: {
    partsList: "配件清单",
    accessoryImage: "配件图片",
    // fullDirectory: "目录",
    expansion: "展开",
    putAway: "收起",
    top: "顶部",
    print: "打印",
    // directory: "目录",
    fullScreen: "全屏",
    exitScreen: "退出全屏",
    enlarge: "放大",
    reduce: "缩小",
    restore: "还原",
  },
  toolBox: {
    title: "专用工具",
    factory: "厂家",
    assemblyType: "总成类型",
    assemblyName: "总成",
    toolName: "工具",
    factoryTip: "请选择厂家",
    typeTip: "请选择总成类型",
    assemblyTip: "请输入总成名称或编码搜索",
    toolTip: "请输入工具名称或编码搜索",
    toolTitle: "虚拟工具箱",
  },
  pagingText: {
    firstPage: "首页",
    lastPage: "尾页",
    previousPage: "上一页",
    nextPage: "下一页",
  },
  footer: {
    copyright: "Copyright © 北汽福田汽车股份有限公司版权所有",
  },
  ...zhLocale,
};
export default zh;
