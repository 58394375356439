<template>
  <div
    v-loading="show"
    class="loadMessage"
    element-loading-spinner="el-icon-loading"
    :element-loading-text="loadPrompt"
  ></div>
</template>
<script>
import i18n from "@/i18n/i18n";
export default {
  name: "loadingIndex",
  props: {
    show: Boolean,
  },
  computed: {
    loadPrompt() {
      return i18n.t("feedback.submit");
    },
  },
};
</script>
