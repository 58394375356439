<template>
  <div class="loginContainer" v-cloak>
    <div class="headerArea">
      <div class="logoCenter">
        <img src="../../assets/image/login/logo.png" alt="" />
      </div>
      <div class="navRightArea">
        <div class="language">
          <el-select v-model="languageVal">
            <el-option
              @click.native="langeChange('zh-CN')"
              :label="$t('header.cn')"
              :value="$t('header.cn')"
            ></el-option>
            <el-option
              @click.native="langeChange('en-US')"
              :label="$t('header.en')"
              :value="$t('header.en')"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="loginCenter">
      <div class="loginImg">
        <div class="imgOne"></div>
        <div class="imgTwo"></div>
      </div>
      <div class="loginDetail">
        <div class="title">{{ $t("login.title") }}</div>
        <el-form
          ref="loginform"
          :model="loginform"
          :rules="loginrules"
          class="loginform"
          autocomplete="on"
          label-position="left"
        >
          <el-form-item prop="username">
            <el-input
              type="text"
              autocomplete="off"
              :placeholder="$t('login.userPlaceholder')"
              v-model="loginform.username"
            >
              <i slot="prefix" class="el-input__icon icon-user"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              class="password"
              type="password"
              autocomplete="off"
              :placeholder="$t('login.passPlaceholder')"
              v-model="loginform.password"
            >
              <i slot="prefix" class="el-input__icon icon-password"></i>
            </el-input>
            <img
              class="seeArea"
              @click="noSeeClick"
              src="../../assets/image/login/visibleIcon.png"
            />
            <img
              class="noSeeArea"
              @click="seeClick"
              src="../../assets/image/login/notVisible.png"
            />
          </el-form-item>
          <el-form-item prop="captCha" class="captCha">
            <el-input
              type="text"
              autocomplete="off"
              :placeholder="$t('login.captChaPlaceholder')"
              v-model="loginform.captCha"
            >
              <i slot="prefix" class="el-input__icon icon-captCha"></i>
            </el-input>
            <img
              @click="getCaptCha"
              :src="loginform.src"
              alt=""
              class="CaptChaImg"
            />
            <input type="hidden" v-model="loginform.id" />
            <span @click="getCaptCha">{{ $t("login.replaceCode") }}</span>
          </el-form-item>
          <el-form-item class="loginBut">
            <el-button class="button" @click="login" type="primary">{{
              $t("login.logIn")
            }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { login, captCha, getCurrentInfo } from "@/api/api.js";
import { backendTisUrl } from "@/assets/js/common.js";
export default {
  name: "loginPage",
  data() {
    return {
      loginform: {
        username: "",
        password: "",
        captCha: "",
        id: null,
        src: "",
      },
      languageVal: "",
      timer: "",
      num: 0,
      loginStatus: false,
    };
  },
  computed: {
    loginrules() {
      return {
        username: [
          {
            required: true,
            message: this.$t("login.userMessage"),
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("login.passMessage"),
            trigger: ["blur", "change"],
          },
        ],
        captCha: [
          {
            required: true,
            message: this.$t("login.captChaMessage"),
            trigger: ["blur", "change"],
          },
        ],
      };
    },
  },
  methods: {
    // 语言切换
    langeChange(val) {
      if (localStorage.toolLanguage != val) {
        localStorage.toolLanguage = val;
        this.$i18n.locale = val;
        this.languageVal =
          localStorage.toolLanguage == "zh-CN" ? "中文" : "English";
        this.$store.commit("langSwitch");
      }
    },
    // 密码可见
    seeClick() {
      $(".seeArea").show();
      $(".noSeeArea").hide();
      $(".password input").attr("type", "text");
    },
    // 密码不可见
    noSeeClick() {
      this.pswstate();
    },
    pswstate() {
      $(".seeArea").hide();
      $(".noSeeArea").show();
      $(".password input").attr("type", "password");
    },
    // 验证码
    getCaptCha() {
      captCha().then((res) => {
        if (res.data.code === 100) {
          this.loginform.id = res.data.data.id;
          this.loginform.src = res.data.data.image;
        } else {
          this.$DonMessage.error(res.data.msg);
        }
      });
    },
    keyDown(e) {
      if (e.keyCode === 13) {
        this.login();
      }
    },
    // 登录
    login() {
      var _this = this;
      if (_this.loginStatus == true) {
        _this.$DonMessage.error(this.$t("login.loginTips"));
        return;
      }
      if (!_this.loginform.username) {
        _this.$DonMessage.error(this.$t("login.userMessage"));
        return;
      }
      if (!_this.loginform.password) {
        _this.$DonMessage.error(this.$t("login.passMessage"));
        return;
      }
      if (!_this.loginform.captCha) {
        _this.$DonMessage.error(this.$t("login.captChaMessage"));
        return;
      }
      _this.loginStatus = true;
      var params = {
        username: _this.loginform.username,
        password: _this.loginform.password,
        vid: _this.loginform.id,
        verifycode: _this.loginform.captCha,
        type: "1",
      };
      login(params)
        .then((res) => {
          if (res.data.code === 100) {
            var list = res.data.data;
            var tokenVal = list.tokenHead + list.token;
            _this.$store.commit("set_token", tokenVal);
            _this.$store.commit("set_userName", params.username);
            _this.$store.commit("setRefreshToken", list.refreshToken);
            sessionStorage.setItem("store", JSON.stringify(this.$store.state));
            _this.initialState(list.initial);
            clearTimeout(this.timer);
            if (window.bc) {
              window.bc.postMessage(JSON.stringify(this.$store.state));
            }
            _this.loginStatus = true;
          } else {
            _this.loginStatus = false;
            _this.$DonMessage.error(res.data.msg);
          }
        })
        .catch((err) => {
          if (err != null && err !== "" && err.responseText !== null) {
            _this.loginStatus = false;
            _this.$DonMessage.error(_this.$t("login.loginException"));
          }
        });
    },
    initialState(initialVal) {
      if (initialVal == true) {
        this.$router.push("/initialPassword");
      } else {
        this.getUserData();
      }
    },
    // 当前用户信息
    getUserData() {
      getCurrentInfo().then((res) => {
        if (res.data.code == 100) {
          var list = res.data.data;
          var type = list.loginType;
          // 2：前台    1：后台
          if (type == "1") {
            window.location = backendTisUrl;
          } else if (type == "2") {
            this.$router.push("/index");
          }
          this.loginStatus = true;
        } else {
          this.loginStatus = false;
          this.$DonMessage.error(res.data.msg);
        }
      });
    },
    // 图片切换
    changeTimer() {
      this.timer = setInterval(() => {
        this.num++;
        if (this.num == 2) {
          this.num = 0;
        }
        this.changeImage(this.num);
      }, 5100);
    },
    changeImage(num) {
      var index = num + 1;
      $(".loginImg > div").css("opacity", "0");
      $(".loginImg > div:nth-child(" + index + ")").css("opacity", "1");
    },
    loginArea() {
      setTimeout(() => {
        var allHeight = $(".loginContainer").outerHeight(true);
        var val = allHeight;
        $(".loginCenter").css("height", val);
      });
    },
    loginSize() {
      var _this = this;
      _this.loginArea();
      window.addEventListener("resize", function () {
        _this.loginArea();
      });
    },
  },
  mounted() {
    this.languageVal =
      localStorage.toolLanguage == "zh-CN" ? "中文" : "English";
    this.getCaptCha();
    this.loginSize();
    window.addEventListener("keydown", this.keyDown);
    this.$store.commit("del_token");
    this.changeTimer();
    this.pswstate();
    setTimeout(() => {
      $(".mask_div").remove();
    });
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
};
</script>
<style>
/* 登录背景 */
.loginContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
/* 顶部导航 */
.headerArea {
  width: 98%;
  height: 70px;
  background: #000;
  background-size: cover;
  padding: 0 2% 0 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}
.loginContainer .headerArea {
  width: 98%;
  height: 70px;
  background: transparent;
  background-size: cover;
  padding: 0 2% 0 0%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}
/* logo */
.logoCenter {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 2% 0 1%;
}
.logoCenter img {
  height: 46px;
}
/* svg图标展示 */
.navRightArea {
  height: 100%;
  display: flex;
  align-items: center;
}
.navRightArea > div {
  margin-left: 42px;
}
.navRightArea svg {
  width: 28px;
  height: 28px;
  color: #fff !important;
  cursor: pointer;
}
/* 语言切换 */
.navRightArea .language .el-select {
  font-size: 14px;
  width: 110px;
  color: #fff !important;
}
.navRightArea .language .el-select .el-input__inner {
  border: 1px solid #fff !important;
  border-radius: 50px;
  text-align: center;
  color: #fff !important;
}
.navRightArea .language .el-select .el-input__icon {
  color: #fff !important;
}
.navRightArea .language .el-select .el-input__suffix {
  right: 5px;
}
.loginImg {
  width: 100%;
  height: 100%;
  position: relative;
}
.loginImg > div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 5s;
  opacity: 0;
  background-repeat: no-repeat;
  background-position: center 65%;
  background-size: cover;
}
.loginImg > div:first-child {
  opacity: 1;
}
.loginImg .imgOne {
  background-image: url("../../assets/image/login/bgOne.png");
}
.loginImg .imgTwo {
  background-image: url("../../assets/image/login/bgTwo.png");
}
/* 登录区域 */
.loginCenter {
  position: relative;
  width: 100%;
}
.loginDetail {
  background: #fff;
  width: 460px;
  position: absolute;
  right: 10%;
  top: 21%;
  border-radius: 5px;
}
.loginDetail .title {
  font-size: 28px;
  text-align: center;
  margin: 6% 0;
}
.loginform {
  padding: 0 10%;
}
.icon-user::after,
.icon-password::after,
.icon-captCha::after {
  display: inline-block !important;
  width: 22px !important;
  height: 22px !important;
  background: url("../../assets/image/login/userIcon.png") no-repeat;
  background-size: 100%;
  margin: 0 10px 0 5px;
}
.icon-password::after {
  background: url("../../assets/image/login/passwordIcon.png") no-repeat;
  background-size: 100%;
}
.icon-captCha::after {
  background: url("../../assets/image/login/captchaIcon.png") no-repeat;
  background-size: 100%;
}
.loginform .el-form-item .el-form-item__content {
  border: 1px solid var(--border-color) !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.loginform .el-input__icon,
.loginform .el-form-item__content,
.loginform .el-input .el-input__inner {
  height: 42px !important;
  line-height: 42px !important;
  font-size: 16px !important;
  border: none !important;
}
.loginform .el-form-item__content {
  line-height: 40px !important;
}
.loginform .el-input .el-input__inner::placeholder,
.initialform .el-input .el-input__inner::placeholder {
  color: #ccc !important;
}
.loginform .el-input__prefix {
  border-right: 1px solid;
  border-image: linear-gradient(to bottom, #fff, #e7e7e7, #fff) 1 10;
}
.loginform .el-input--prefix .el-input__inner {
  padding-left: 55px !important;
  padding-right: 8px !important;
}
.loginform .seeArea,
.loginform .noSeeArea {
  height: 22px;
  cursor: pointer;
  margin: 0 5px;
}
.loginform .seeArea {
  display: none;
}
.loginform .loginBut {
  padding: 5% 0 6% 0;
}
.captCha .CaptChaImg {
  height: 42px;
  width: 80px;
}
.captCha .el-form-item__content > span {
  color: #0067b9;
  font-size: 12px;
  margin: 0 5px;
}
.loginform .loginBut .el-form-item__content {
  border: none !important;
}
.loginform .loginBut .el-button {
  background: #0067b9 !important;
  color: #fff !important;
  width: 100%;
  padding: 0;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
  /* 顶部导航 */
  .headerArea,
  .loginContainer .headerArea {
    height: 60px;
  }
  /* logo */
  .logoCenter img {
    height: 40px;
  }
  .navRightArea svg {
    width: 28px;
    height: 28px;
  }
  .navRightArea > div {
    margin-left: 40px;
  }
  /* 登录区域 */
  .loginDetail {
    width: 360px;
    border-radius: 4px;
  }
  .loginDetail .title {
    font-size: 24px;
  }
  .loginform {
    padding: 0 8%;
  }
  .icon-user::after,
  .icon-password::after,
  .icon-captCha::after {
    width: 21px !important;
    height: 21px !important;
    margin: 0 8px 0 3px;
  }
  .loginform .el-form-item .el-form-item__content {
    border-radius: 4px;
  }
  .loginform .el-input__icon,
  .loginform .el-form-item__content,
  .loginform .el-input .el-input__inner {
    height: 36px !important;
    line-height: 36px !important;
    font-size: 15px !important;
  }
  .loginform .el-form-item__content {
    line-height: 36px !important;
  }
  .loginform .el-input--prefix .el-input__inner {
    padding-left: 45px !important;
  }
  .loginform .seeArea,
  .loginform .noSeeArea {
    height: 18px;
  }
  .captCha .CaptChaImg {
    height: 36px;
    width: 60px;
  }
  .loginform .loginBut .el-button {
    height: 38px;
    line-height: 38px;
    font-size: 15px;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1366px) {
  /* 顶部导航 */
  .headerArea,
  .loginContainer .headerArea {
    height: 50px;
  }
  /* logo */
  .logoCenter img {
    height: 35px;
  }
  .navRightArea svg {
    width: 26px;
    height: 26px;
  }
  .navRightArea .language .el-select {
    width: 98px;
  }
  .navRightArea .language .el-select .el-input__inner {
    height: 34px !important;
    line-height: 34px !important;
  }
  .navRightArea .language .el-select .el-input__icon {
    line-height: 34px !important;
  }
  .navRightArea > div {
    margin-left: 40px;
  }
  /* 登录区域 */
  .loginDetail {
    width: 350px;
    right: 8%;
    top: 23%;
    border-radius: 4px;
  }
  .loginDetail .title {
    font-size: 24px;
  }
  .loginform {
    padding: 0 8%;
  }
  .icon-user::after,
  .icon-password::after,
  .icon-captCha::after {
    width: 20px !important;
    height: 20px !important;
    margin: 0 8px 0 3px;
  }
  .loginform .el-form-item .el-form-item__content {
    border-radius: 4px;
  }
  .loginform .el-input__icon,
  .loginform .el-form-item__content,
  .loginform .el-input .el-input__inner {
    height: 35px !important;
    line-height: 35px !important;
    font-size: 15px !important;
  }
  .loginform .el-form-item__content {
    line-height: 33px !important;
  }
  .loginform .el-input--prefix .el-input__inner {
    padding-left: 45px !important;
  }
  .loginform .seeArea,
  .loginform .noSeeArea {
    height: 18px;
  }
  .captCha .CaptChaImg {
    height: 35px;
    width: 55px;
  }
  .loginform .loginBut .el-button {
    height: 36px;
    line-height: 36px;
    font-size: 15px;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1280px) {
  /* 顶部导航 */
  .headerArea,
  .loginContainer .headerArea {
    height: 42px;
  }
  /* logo */
  .logoCenter img {
    height: 32px;
  }
  .navRightArea svg {
    width: 22px;
    height: 22px;
  }
  .navRightArea .language .el-select {
    width: 90px;
  }
  .navRightArea .language .el-select .el-input__inner {
    height: 30px !important;
    line-height: 30px !important;
  }
  .navRightArea .language .el-select .el-input__icon {
    line-height: 30px !important;
  }
  .navRightArea > div {
    margin-left: 38px;
  }
  /* 登录区域 */
  .loginImg > div {
    background-position: center center;
  }
  .loginDetail {
    width: 330px;
    right: 7%;
    top: 20%;
    border-radius: 3px;
  }
  .loginDetail .title {
    font-size: 19px;
  }
  .loginform {
    padding: 0 8%;
  }
  .icon-user::after,
  .icon-password::after,
  .icon-captCha::after {
    width: 18px !important;
    height: 18px !important;
    margin: 0 8px 0 3px;
  }
  .loginform .el-form-item .el-form-item__content {
    border-radius: 3px;
  }
  .loginform .el-input__icon,
  .loginform .el-form-item__content,
  .loginform .el-input .el-input__inner {
    height: 32px !important;
    line-height: 32px !important;
    font-size: 14px !important;
  }
  .loginform .el-form-item__content {
    line-height: 30px !important;
  }
  .loginform .el-input--prefix .el-input__inner {
    padding-left: 45px !important;
  }
  .loginform .seeArea,
  .loginform .noSeeArea {
    height: 15px;
  }
  .captCha .CaptChaImg {
    height: 32px;
    width: 50px;
  }
  .loginform .loginBut .el-button {
    height: 34px;
    line-height: 34px;
    font-size: 14px;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1024px) {
  /* 顶部导航 */
  .headerArea,
  .loginContainer .headerArea {
    height: 38px;
  }
  /* logo */
  .logoCenter img {
    height: 28px;
  }
  .navRightArea svg {
    width: 21px;
    height: 21px;
  }
  .navRightArea .language .el-select {
    width: 85px;
  }
  .navRightArea .language .el-select .el-input__inner {
    height: 28px !important;
    line-height: 28px !important;
  }
  .navRightArea .language .el-select .el-input__icon {
    line-height: 28px !important;
  }
  .navRightArea > div {
    margin-left: 36px;
  }
  /* 登录区域 */
  .loginImg > div {
    background-position: center center;
  }
  .loginDetail {
    width: 300px;
    right: 7%;
    top: 21%;
    border-radius: 3px;
  }
  .loginDetail .title {
    font-size: 18px;
  }
  .loginform {
    padding: 0 7%;
  }
  .icon-user::after,
  .icon-password::after,
  .icon-captCha::after {
    width: 18px !important;
    height: 18px !important;
    margin: 0 8px 0 3px;
  }
  .loginform .el-form-item .el-form-item__content {
    border-radius: 3px;
  }
  .loginform .el-input__icon,
  .loginform .el-form-item__content,
  .loginform .el-input .el-input__inner {
    height: 30px !important;
    line-height: 30px !important;
    font-size: 13px !important;
  }
  .loginform .el-form-item__content {
    line-height: 28px !important;
  }
  .loginform .el-input--prefix .el-input__inner {
    padding-left: 45px !important;
  }
  .loginform .seeArea,
  .loginform .noSeeArea {
    height: 15px;
  }
  .captCha .CaptChaImg {
    height: 30px;
    width: 50px;
  }
  .loginform .loginBut .el-button {
    height: 32px;
    line-height: 32px;
    font-size: 13px;
  }
}
@media screen and (max-width: 1024px) {
  /* 顶部导航 */
  .headerArea,
  .loginContainer .headerArea {
    height: 38px;
  }
  /* logo */
  .logoCenter img {
    height: 28px;
  }
  .navRightArea svg {
    width: 21px;
    height: 21px;
  }
  .navRightArea .language .el-select {
    width: 85px;
  }
  .navRightArea .language .el-select .el-input__inner {
    height: 28px !important;
    line-height: 28px !important;
  }
  .navRightArea .language .el-select .el-input__icon {
    line-height: 28px !important;
  }
  .navRightArea > div {
    margin-left: 34px;
  }
  /* 登录区域 */
  .loginDetail {
    width: 300px;
    right: 7%;
    top: 21%;
    border-radius: 3px;
  }
  .loginDetail .title {
    font-size: 18px;
  }
  .loginform {
    padding: 0 7%;
  }
  .icon-user::after,
  .icon-password::after,
  .icon-captCha::after {
    width: 18px !important;
    height: 18px !important;
    margin: 0 8px 0 3px;
  }
  .loginform .el-form-item .el-form-item__content {
    border-radius: 3px;
  }
  .loginform .el-input__icon,
  .loginform .el-form-item__content,
  .loginform .el-input .el-input__inner {
    height: 30px !important;
    line-height: 30px !important;
    font-size: 13px !important;
  }
  .loginform .el-form-item__content {
    line-height: 28px !important;
  }
  .loginform .el-input--prefix .el-input__inner {
    padding-left: 45px !important;
  }
  .loginform .seeArea,
  .loginform .noSeeArea {
    height: 15px;
  }
  .captCha .CaptChaImg {
    height: 30px;
    width: 50px;
  }
  .loginform .loginBut .el-button {
    height: 32px;
    line-height: 32px;
    font-size: 13px;
  }
}
</style>
