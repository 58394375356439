<template>
  <div class="headerContent" v-cloak>
    <div class="headerArea">
      <div class="logoCenter">
        <img class="logoOne" src="../../assets/image/login/logo.png" alt="" />
      </div>
      <div class="navRightArea">
        <!-- 帮助手册 -->
        <div @click="helpClick()">
          <el-tooltip
            class="item"
            effect="light"
            :content="$t('header.helpManual')"
            placement="bottom"
          >
            <svg-icon icon-class="helpManual"></svg-icon>
          </el-tooltip>
        </div>
        <!-- 我的 -->
        <div v-if="systemState == onlineState">
          <el-dropdown>
            <span>
              <svg-icon icon-class="mine"></svg-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click.native="basicInfo()">
                  {{ $t("basicInfo.title") }}
                </el-dropdown-item>
                <el-dropdown-item @click.native="modifyCipher()">
                  {{ $t("initialPwd.changePwd") }}
                </el-dropdown-item>
                <el-dropdown-item @click.native="exitClick()">
                  {{ $t("header.exit") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <!-- 语言切换 -->
        <div>
          <el-dropdown>
            <span>
              <svg-icon icon-class="language"></svg-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click.native="langChange('zh-CN')">
                  {{ $t("header.cn") }}
                </el-dropdown-item>
                <el-dropdown-item @click.native="langChange('en-US')">
                  {{ $t("header.en") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <el-dialog
      :modal-append-to-body="false"
      :visible.sync="dialogFormVisible"
      :close-on-press-escape="false"
    >
      <div slot="title" class="header-title" v-if="dialogStatus == 'basicInfo'">
        <svg-icon icon-class="basicInfo"></svg-icon>
        <span>{{ $t("basicInfo.title") }}</span>
      </div>
      <div
        slot="title"
        class="header-title"
        v-if="dialogStatus == 'modifyCipher'"
      >
        <svg-icon icon-class="modifyCipher"></svg-icon>
        <span>{{ $t("initialPwd.changePwd") }}</span>
      </div>
      <div v-if="dialogStatus == 'basicInfo'">
        <el-form
          ref="form"
          :model="form"
          :label-width="infoLabelWidth"
          :rules="infoRules"
        >
          <el-form-item :label="$t('table.serviceCode')" prop="username">
            <el-input
              maxlength="100"
              v-model="form.username"
              disabled=""
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('table.serviceName')" prop="realName">
            <el-input
              maxlength="100"
              v-model="form.realName"
              disabled=""
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('basicInfo.gender')" prop="gender">
            <el-radio-group v-model="form.gender">
              <el-radio :label="1">{{ $t("basicInfo.male") }}</el-radio>
              <el-radio :label="2">{{ $t("basicInfo.female") }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('basicInfo.telephone')" prop="mobile">
            <el-input
              maxlength="11"
              v-model="form.mobile"
              :placeholder="$t('basicInfo.telePlaceholder')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('basicInfo.address')" prop="address">
            <el-input
              v-model="form.address"
              maxlength="200"
              :placeholder="$t('basicInfo.addressPlaceholder')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('basicInfo.mailbox')" prop="email">
            <el-input
              v-model="form.email"
              maxlength="50"
              :placeholder="$t('basicInfo.mailPlaceholder')"
            ></el-input>
          </el-form-item>
          <div class="buttonArea">
            <el-button @click="infoSubmit('form')">
              {{ $t("button.submit") }}
            </el-button>
          </div>
        </el-form>
      </div>
      <div v-if="dialogStatus == 'modifyCipher'" class="updatePswArea">
        <el-form
          :model="pwsForm"
          :label-width="pwsLabelWidth"
          ref="pwsForm"
          :rules="cipherRules"
        >
          <el-form-item
            prop="oldPassword"
            class="oldPassword"
            :label="$t('initialPwd.oldPassword')"
          >
            <el-input
              minlength="6"
              maxlength="16"
              v-model="pwsForm.oldPassword"
              :placeholder="$t('initialPwd.placeholderThree')"
              type="password"
            >
            </el-input>
            <img
              class="seeArea"
              @click="noSeeClick('oldPassword')"
              src="../../assets/image/login/visibleIcon.png"
            />
            <img
              class="noSeeArea"
              @click="seeClick('oldPassword')"
              src="../../assets/image/login/notVisible.png"
            />
          </el-form-item>
          <el-form-item
            prop="newPassword"
            class="newPassword"
            :label="$t('initialPwd.password')"
          >
            <el-input
              minlength="6"
              maxlength="16"
              v-model="pwsForm.newPassword"
              :placeholder="$t('initialPwd.placeholderOne')"
              type="password"
            ></el-input>
            <img
              class="seeArea"
              @click="noSeeClick('newPassword')"
              src="../../assets/image/login/visibleIcon.png"
            />
            <img
              class="noSeeArea"
              @click="seeClick('newPassword')"
              src="../../assets/image/login/notVisible.png"
            />
          </el-form-item>
          <el-form-item
            prop="repeatPwd"
            class="repeatPwd"
            :label="$t('initialPwd.confirmPwd')"
          >
            <el-input
              minlength="6"
              maxlength="16"
              v-model="pwsForm.repeatPwd"
              :placeholder="$t('initialPwd.placeholderTwo')"
              type="password"
            ></el-input>
            <img
              class="seeArea"
              @click="noSeeClick('repeatPwd')"
              src="../../assets/image/login/visibleIcon.png"
            />
            <img
              class="noSeeArea"
              @click="seeClick('repeatPwd')"
              src="../../assets/image/login/notVisible.png"
            />
          </el-form-item>
          <div class="buttonArea">
            <el-button @click="passwordSubmit('pwsForm')">
              {{ $t("button.submit") }}
            </el-button>
          </div>
        </el-form>
        <div class="modifyTip">
          <div>
            <img src="../../assets/image/login/successIcon.png" alt="" />
          </div>
          <div>
            {{ $t("initialPwd.tipsOne") }}
            <b> {{ time }} </b>
            {{ $t("initialPwd.tipsTwo") }}
          </div>
          <div @click="loginClick()">{{ $t("initialPwd.login") }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { addTabs, widthLabel } from "@/assets/js/common.js";
import {
  updatePsw,
  getCurrentInfo,
  userUpdate,
  logout,
  switchLang,
} from "@/api/api.js";
export default {
  name: "navHeader",
  data() {
    return {
      dialogFormVisible: false,
      time: 5,
      dialogStatus: "",
      form: {
        // theme: "", // 主题
        username: "", // 账号名
        realName: "", // 姓名
        // contacts: "", // 联系人
        gender: "", // 性别
        email: "", // 邮箱
        mobile: "", // 电话
        address: "", // 地址
      },
      pwsForm: {
        oldPassword: "", // 旧密码
        newPassword: "", // 新密码
        repeatPwd: "", // 确认密码
      },
      systemState: "", // 当前状态
      onlineState: "", // 线束状态
    };
  },
  watch: {
    time(newVal) {
      if (newVal == 0) {
        this.exitClick();
        this.dialogFormVisible = false;
      }
    },
  },
  computed: {
    infoLabelWidth() {
      var val = widthLabel();
      return this.$i18n.locale == "zh-CN"
        ? `${val}`
        : `${Number(val.split("px")[0]) + 15}px`;
    },
    pwsLabelWidth() {
      var val = widthLabel();
      return this.$i18n.locale == "zh-CN"
        ? `${val}`
        : `${Number(val.split("px")[0]) + 15}px`;
    },
    infoRules() {
      return {
        email: [
          {
            type: "email",
            required: false,
            message: this.$t("basicInfo.mailboxTips"),
            trigger: ["blur", "change"],
          },
        ],
        mobile: [
          {
            required: false,
            pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
            message: this.$t("basicInfo.telephoneTips"),
            trigger: ["blur", "change"],
          },
        ],
      };
    },
    cipherRules() {
      return {
        oldPassword: [
          {
            required: true,
            message: this.$t("initialPwd.oldTip"),
            trigger: ["blur", "change"],
          },
        ],
        newPassword: [
          {
            required: true,
            message: this.$t("initialPwd.newTip"),
            trigger: ["blur", "change"],
          },
          {
            pattern: /^(?=.*\d+)(?=.*[A-Za-z]+)[\w]{6,16}$/,
            required: true,
            message: this.$t("initialPwd.newFormat"),
            trigger: ["blur", "change"],
          },
        ],
        repeatPwd: [
          {
            required: true,
            message: this.$t("initialPwd.passTip"),
            trigger: ["blur", "change"],
          },
        ],
      };
    },
  },
  methods: {
    beforeunloadFn(e) {
      this.time = 5;
    },
    // 帮助手册
    helpClick() {
      this.$router.push({ name: "helpPage" });
      addTabs(this.$route.path, this.$t("header.helpManual"));
    },
    // 个人信息
    currentInfoData() {
      getCurrentInfo().then((res) => {
        if (res.data.code == 100) {
          var list = res.data.data;
          // this.form.theme = list.theme;
          this.form.username = list.username;
          this.form.realName = list.realName;
          // this.form.contacts = list.contacts;
          this.form.gender = list.sex;
          this.form.email = list.email;
          this.form.mobile = list.mobile;
          this.form.address = list.address;
        } else {
          this.$DonMessage.error(res.data.msg);
        }
      });
    },
    // 基础信息
    infoReset() {
      this.form.username = "";
      this.form.realName = "";
      // this.form.contacts = "";
      this.form.gender = "";
      this.form.email = "";
      this.form.mobile = "";
      this.form.address = "";
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    basicInfo() {
      this.dialogStatus = "basicInfo";
      this.infoReset();
      this.currentInfoData();
      this.dialogFormVisible = true;
    },
    // 基本信息修改提交
    infoSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$loading.show();
          var params = new FormData();
          params.append("sex", this.form.gender);
          // params.append("contacts", this.form.contacts);
          params.append("mobile", this.form.mobile);
          params.append("email", this.form.email);
          params.append("address", this.form.address);
          userUpdate(params).then((res) => {
            if (res.data.code == 100) {
              this.$DonMessage.success(this.$t("successTips.modified"));
              this.dialogFormVisible = false;
            } else {
              this.$DonMessage.error(res.data.msg);
            }
            this.$loading.hide();
          });
        }
      });
    },
    // 密码不可见
    noSeeClick(text) {
      $("." + text + " .seeArea").hide();
      $("." + text + " .noSeeArea").show();
      $("." + text + " .el-input .el-input__inner").attr("type", "password");
    },
    // 密码可见
    seeClick(text) {
      $("." + text + " .seeArea").show();
      $("." + text + " .noSeeArea").hide();
      $("." + text + " .el-input .el-input__inner").attr("type", "text");
    },
    // 修改密码
    passwordReset() {
      $(".seeArea").hide();
      $(".noSeeArea").show();
      $(".updatePswArea .el-input .el-input__inner").attr("type", "password");
      this.pwsForm.oldPassword = "";
      this.pwsForm.newPassword = "";
      this.pwsForm.repeatPwd = "";
      this.$nextTick(() => {
        this.$refs.pwsForm.clearValidate();
      });
    },
    modifyCipher() {
      this.dialogStatus = "modifyCipher";
      this.passwordReset();
      this.dialogFormVisible = true;
    },
    countDown() {
      var _this = this;
      this.time--;
    },
    // 确认提交
    passwordSubmit(pwsForm) {
      this.$refs[pwsForm].validate((valid) => {
        if (valid) {
          this.$loading.show();
          var params = new FormData();
          params.append("oldPwd", this.pwsForm.oldPassword);
          params.append("newPwd", this.pwsForm.newPassword);
          params.append("confirmPwd", this.pwsForm.repeatPwd);
          updatePsw(params)
            .then((res) => {
              if (res.data.code === 100) {
                $(".updatePswArea .el-form,.el-dialog__header").hide();
                $(".updatePswArea .modifyTip").show();
                this.time = 5;
                setInterval(this.countDown, 1000);
              } else {
                this.$DonMessage.error(res.data.msg);
              }
              this.$loading.hide();
            })
            .catch((err) => {
              this.$loading.hide();
            });
        }
      });
    },
    loginClick() {
      this.exitClick();
      setTimeout(() => {
        this.dialogFormVisible = false;
      }, 100);
    },
    // 退出
    exitClick() {
      logout()
        .then((res) => {
          this.$store.commit("del_token");
          this.$router.push("/login");
        })
        .catch((e) => {
          this.$store.commit("del_token");
        });
    },
    // 语言切换
    langChange(val) {
      if (localStorage.toolLanguage != val) {
        localStorage.toolLanguage = val;
        this.$i18n.locale = val;
        this.$store.commit("langSwitch");
      }
    },
  },
  mounted() {
    var _this = this;
    _this.systemState = _this.$store.state.systemState;
    _this.onlineState = _this.$store.state.onlineState;
  },
  created() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadFn(e));
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadFn(e));
  },
};
</script>
<style>
/* 顶部导航 */
.logoTwo {
  display: none;
}
.headerArea.buleTheme .logoCenter {
  background: linear-gradient(
    90deg,
    var(--theme-bgColor),
    rgb(156, 217, 246),
    #019ee9
  );
}
.headerArea.buleTheme {
  background: #019ee9;
}
.navRightArea .el-badge__content.is-fixed {
  top: 4px;
}
/* 基本信息 */
.headerContent .el-dialog {
  width: 550px;
}
.headerContent .el-dialog .el-form {
  padding: 0 8% 1% 3%;
}
.headerContent .buttonArea {
  margin-left: 2.5%;
}
/* 修改密码 */
.headerContent .el-dialog .updatePswArea .el-form .el-input__inner {
  padding: 0 30px 0 8px !important;
}
.headerContent .el-dialog .updatePswArea .el-form img {
  cursor: pointer;
  width: 20px;
  position: absolute;
  top: 50%;
  right: 0;
  padding: 0 5px;
  transform: translateY(-50%);
}
.headerContent .updatePswArea .seeArea {
  display: none;
}
.headerContent .updatePswArea .seeArea,
.headerContent .updatePswArea .noSeeArea {
  cursor: pointer;
}
.updatePswArea .modifyTip {
  display: none;
  text-align: center;
  font-size: 15px;
  padding: 6% 0;
}
.updatePswArea .modifyTip > div {
  margin: 15px 0;
}
.updatePswArea .modifyTip > div:last-child {
  color: var(--theme-color);
  text-decoration: underline;
  cursor: pointer;
}
.updatePswArea .modifyTip img {
  width: 80%;
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
  /* 顶部导航 */
  /* 基本信息 */
  .headerContent .el-dialog {
    width: 505px;
  }
  .headerContent .el-dialog .el-form {
    padding: 0 8% 1% 2%;
  }
  .headerContent .buttonArea {
    margin-left: 3%;
  }
  /* 修改密码 */
  .headerContent .el-dialog .updatePswArea .el-form .el-input__inner {
    padding: 0 28px 0 8px !important;
  }
  .headerContent .el-dialog .updatePswArea .el-form img {
    width: 18px;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1366px) {
  /* 顶部导航 */
  /* 基本信息 */
  .headerContent .el-dialog {
    width: 485px;
  }
  /* 修改密码 */
  .headerContent .el-dialog .updatePswArea .el-form .el-input__inner {
    padding: 0 26px 0 8px !important;
  }
  .headerContent .el-dialog .updatePswArea .el-form img {
    width: 16px;
  }
  .headerContent .el-dialog .el-form {
    padding: 0 8% 1% 1%;
  }
  .headerContent .buttonArea {
    margin-left: 3.5%;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1280px) {
  /* 顶部导航 */
  /* 基本信息 */
  .headerContent .el-dialog {
    width: 440px;
  }
  .headerContent .el-dialog .el-form {
    padding: 0 8% 1% 0%;
  }
  .headerContent .buttonArea {
    margin-left: 4%;
  }
  /* 修改密码 */
  .headerContent .el-dialog .updatePswArea .el-form .el-input__inner {
    padding: 0 26px 0 8px !important;
  }
  .headerContent .el-dialog .updatePswArea .el-form img {
    width: 16px;
  }
  .updatePswArea .modifyTip {
    font-size: 14px;
    padding: 6% 0;
  }
  .updatePswArea .modifyTip > div {
    margin: 12px 0;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1024px) {
  /* 顶部导航 */
  /* 基本信息 */
  .headerContent .el-dialog {
    width: 405px;
  }
  .headerContent .el-dialog .el-form {
    padding: 0 8% 1% 0%;
  }
  .headerContent .buttonArea {
    margin-left: 4%;
  }
  /* 修改密码 */
  .headerContent .el-dialog .updatePswArea .el-form .el-input__inner {
    padding: 0 26px 0 8px !important;
  }
  .headerContent .el-dialog .updatePswArea .el-form img {
    width: 16px;
  }
  .updatePswArea .modifyTip {
    font-size: 13px;
    padding: 5% 0;
  }
  .updatePswArea .modifyTip > div {
    margin: 10px 0;
  }
}
@media screen and (max-width: 1024px) {
  /* 顶部导航 */
  /* 基本信息 */
  .headerContent .el-dialog {
    width: 425px;
  }
  .headerContent .el-dialog .el-form {
    padding: 0 8% 1% 0%;
  }
  .headerContent .buttonArea {
    margin-left: 4%;
  }
  .updatePswArea .modifyTip {
    font-size: 13px;
    padding: 5% 0;
  }
  .updatePswArea .modifyTip > div {
    margin: 10px 0;
  }
}
</style>
