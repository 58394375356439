import enLocale from "element-ui/lib/locale/lang/en";
const en = {
  // 登录
  login: {
    title: "Account Login",
    userPlaceholder: "Please enter the user name",
    passPlaceholder: "Please enter password",
    captChaPlaceholder: "Please enter the verification code",
    userMessage: "The user name cannot be empty",
    passMessage: "The password cannot be empty",
    captChaMessage: "The verification code cannot be empty",
    logIn: "Log In",
    infoError: "Incorrect username or password",
    codeLapse:
      "The verification code has expired. Please refresh the verification code and try again",
    codeError: "Verification code error",
    loginFailure: "System login failure",
    loginException: "System login exception",
    loginTips: "Do not repeat operations while logging in",
    replaceCode: "Change",
    tokenExpire: "Sorry, the current login has expired, please login again",
  },
  // 修改初始口令
  initialPwd: {
    title: "Changing the initial password",
    changePwd: "Change Password",
    accountNum: "Account Number",
    oldPassword: "Old Password",
    password: "New Password",
    confirmPwd: "Confirm Password",
    placeholderOne: "Please enter a new password",
    placeholderTwo: "Please enter your confirmation password",
    placeholderThree: "Please enter the old password",
    newTip: "The new password cannot be empty",
    newFormat: "The new password is a combination of 6-16 letters and numbers",
    passTip: "Confirm that the password cannot be empty",
    oldTip: "The old password cannot be empty",
    determine: "Determine",
    reset: "Reset",
    tipsOne:
      "Password changed successfully, will return to the login interface in ",
    tipsTwo: "seconds, please login again!",
    login: "Sign in now",
  },
  header: {
    title: "Foton Special Service Tool System",
    fuelVehicle: "Fuel Vehicle",
    newEnergy: "New Energy",
    search: "Search",
    bulletin: "Technical Service Bulletin",
    notice: "System Notice",
    feedback: "Online Feedback",
    feedbackRecords: "Feedback History",
    helpManual: "Help Manual",
    collect: "My Favorite",
    exit: "Exit",
    en: "English",
    cn: "中文",
    brand: "Foton Motor",
  },
  basicInfo: {
    title: "Basic Information",
    account: "Account Name",
    name: "Name",
    gender: "Gender",
    male: "Male",
    female: "Female",
    contacts: "Contacts",
    contactPlaceholder: "Please enter contact person",
    telephone: "Telephone",
    telePlaceholder: "Please enter phone number",
    address: "Address",
    addressPlaceholder: "Please enter the address",
    mailbox: "E-mail",
    mailPlaceholder: "Please enter email",
    mailboxTips: "Please enter the correct email address",
    telephoneTips: "Please enter the correct phone number",
  },

  button: {
    query: "Query",
    reset: "Reset",
    delete: "Delete",
    cancel: "Cancel",
    confirm: "Confirm",
    submit: "Submit",
    submitModify: "Commit Changes",
    search: "Search",
    previousPage: "Previous",
    nextPage: "Next Page",
    close: "Close",
    feedback: "Feedback",
    download: "Download",
    preview: "Preview",
    upload: "Upload",
    batchDelete: "Batch Deletion",
    leftRotation: "Left Rotation",
    rightRotation: "Right Rotation",
    uploadTip:
      "Supports uploading files in jpg, png, mp3, mp4, xls, xlsx, doc, docx, and zip formats, with a maximum of 5 files and a maximum size of 100M per file",
    know: "Got It",
  },
  table: {
    serialNum: "NO.",
    Hits: "Hits",
    releaseTime: "Release Time",
    serviceCode: "Service Store Code",
    serviceName: "Service Store Name",
    questionerName: "Questioner's Name",
    callerNumber: "Caller Number",
    questionType: "Question Classification",
    problemStatus: "Problem Status",
    problemTypes: "Question Type",
    questionTopic: "Question Subject",
    questionTime: "Question Time",
    firstTime: "First response time",
    problemDesc: "Problem Description",
    accessory: "Accessory",
    directory: "Catalog",
    notes: "Remark",
    manualType: "Manual Type",
    operate: "Operation",
  },
  search: {
    title: "Search Result",
    vehicleSeries: "Vehicle Series",
    vehicleType: "Vehicle Type",
    vehicleCode: "Vehicle Number",
    seriesPlaceholder: "Please select vehicle series",
    typePlaceholder: "Please select vehicle type",
    codePlaceholder: "Please select vehicle number",
    searchPlaceholder: "Please select or enter search keywords",
    searchTotal: "A total of",
    totalUnit: "matching items were found.",
    noTipOne: "Nothing matching",
    noTipTwo: "was found.",
  },
  identifying: {
    unread: "Unread",
    urgent: "Urgent Problem",
    loadTips: "Loading...",
    deleteTip: "Are you sure to delete relevant information?",
    removeFile: "Are you sure to remove the selected file?",
    loginTip: "Please login again",
    browsingHistory: "Recent browsing history",
    noData: "No Data",
    pwsTips:
      "Password changed successfully, will return to the login interface in ",
    pwsTipsTwo: "seconds, please login again!",
    length: "A maximum of 100 characters",
    historicalReply: "Historical Reply",
    Respondent: "Reply",
    recoveryTime: "Recovery Time",
    probe: "Probe",
    consult: "Online Consultation",
    contact: "Customer Service",
    hotLine: "Service Hotline",
    fileLimit: "The uploaded file cannot exceed 100M!",
    fileTip: "The current limit is to upload up to 5 files",
    uploadType: "Uploading with the same file name is not supported",
    imgPreview: "Click on the image to enlarge and view",
    noReply: "No Reply",
    errorPrompt: "The system has deviated slightly...",
  },
  navTab: {
    tabTitle: "Home Page",
    searchResult: "Search Result",
    VRRetrieval: "VR Retrieval",
    helpManual: "Help Manual",
    closeOther: "Close Other",
    closeAll: "Close All",
    more: "More",
  },
  // 反馈
  feedback: {
    submit: "In process...",
  },
  successTips: {
    submit: "Submit Successfully",
    modified: "Modified Successfully",
    save: "Save Successfully",
    operation: "Successful Operation",
    delete: "Successfully Deleted",
    upload: "Upload Successfully",
  },
  // 配件详情
  partsDetail: {
    partsList: "Accessory List",
    accessoryImage: "Accessory Image",
    // fullDirectory: "Catalog",
    expansion: "Expansion",
    putAway: "Put Away",
    top: "Top",
    print: "Print",
    // directory: "Catalog",
    fullScreen: "Full Screen",
    exitScreen: "Exit Full Screen",
    enlarge: "Enlarge",
    reduce: "Reduce",
    restore: "Restore",
  },
  toolBox: {
    title: "Service Special Tool",
    factory: "Manufactory",
    assemblyType: "Assembly Type",
    factoryTip: "Please select the manufacturer",
    typeTip: "Please select the assembly type",
    inputPlaceholder: "Please select the type of manual",
    assemblyName: "Assembly",
    toolName: "Tool",
    assemblyTip: "Please enter assembly name or code search",
    toolTip: "Please enter tool name or code search",
    toolTitle: "Virtual Toolbox",
  },
  pagingText: {
    firstPage: "First Page",
    lastPage: "Last Page",
    previousPage: "Previous Page",
    nextPage: "Next Page",
  },
  footer: {
    copyright: "Copyright © Foton Motor Inc. All rights reserved. ",
  },
  ...enLocale,
};
export default en;
