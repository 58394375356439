import Vue from "vue";
import { Loading } from "element-ui";
Vue.use(Loading.directive);
import componentLoading from "./loadingIndex.vue";

const comLoading = Vue.extend(componentLoading); //定义Loading的构造器

const instance = new comLoading({
  el: document.createElement("div"),
}); //创建实例
instance.show = false;
const loading = {
  show() {
    // 显示方法：挂载实例到body上
    instance.show = true;
    document.body.appendChild(instance.$el);
  },
  hide() {
    instance.show = false;
    // 隐藏方法：从body上写在实例
    // document.body.removeChild(LoadingInstance.$el);
  },
};

//创建Loading插件
export default {
  //插件是一个对象时，必须提供 install 方法
  install() {
    if (!Vue.$loading) {
      Vue.$loading = loading;
    }
    //绑定$loading

    //全局混入
    Vue.mixin({
      created() {
        this.$loading = Vue.$loading;
      },
    });
  },
};
