import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import DonMessage from "../store/message";
import store from "../store/index";
Vue.use(VueRouter);
import offlineRouter from "./modules/offlineRouter"; // 离线路由 1
import login from "@/views/login/loginPage.vue";
import initialPassword from "@/views/login/initialPassword.vue";
var routes = [];
var comment = {
  path: "/HomeView",
  name: "HomeView",
  component: HomeView,
};
if (store.state.systemState == store.state.onlineState) {
  let online = [
    {
      path: "/",
      name: "login",
      component: login,
    },
    {
      path: "/login",
      name: "login",
      component: login,
    },
    {
      path: "/initialPassword",
      name: "initialPassword",
      component: initialPassword,
    },
  ];
  online.push(comment);
  routes = online.concat(offlineRouter);
} else {
  let offline = [
    {
      path: "/",
      name: "home",
      redirect: "/home",
    },
  ];
  offline.push(comment);
  routes = offline.concat(offlineRouter);
}

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});
if (store.state.systemState == store.state.onlineState) {
  if (sessionStorage.getItem("token")) {
    store.commit("set_token", sessionStorage.getItem("token"));
  }
  router.beforeEach((to, from, next) => {
    if (to.path === "/") {
      next();
    } else {
      if (store.state.token) {
        next();
      } else {
        store.commit("del_token");
        // store.commit("clearVUEX");
        // sessionStorage.clear();
        next({ name: "login" });
        // DonMessage.error("请登录");
      }
    }
  });
}
export default router;
