<template>
  <div class="initialContainer" v-cloak>
    <div class="headerArea">
      <div class="logoCenter">
        <img src="../../assets/image/login/logo.png" alt="" />
      </div>
      <div class="navRightArea">
        <div class="language">
          <el-select v-model="languageVal">
            <el-option
              @click.native="langeChange('zh-CN')"
              :label="$t('header.cn')"
              :value="$t('header.cn')"
            ></el-option>
            <el-option
              @click.native="langeChange('en-US')"
              :label="$t('header.en')"
              :value="$t('header.en')"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="initialCenter">
      <div class="title">{{ $t("initialPwd.title") }}</div>
      <el-form
        ref="form"
        :model="form"
        :label-width="labelWidth"
        :rules="formRules"
        class="initialform"
      >
        <el-form-item :label="$t('initialPwd.accountNum')" prop="account">
          <el-input v-model="form.account" readonly></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('initialPwd.password')"
          prop="password"
          class="password"
        >
          <el-input
            v-model="form.password"
            maxlength="16"
            :placeholder="$t('initialPwd.placeholderOne')"
            type="password"
          ></el-input>
          <img
            class="seeArea"
            @click="noSeeClick('password')"
            src="../../assets/image/login/visibleIcon.png"
            alt=""
          />
          <img
            class="noSeeArea"
            @click="seeClick('password')"
            src="../../assets/image/login/notVisible.png"
            alt=""
          />
        </el-form-item>
        <el-form-item
          :label="$t('initialPwd.confirmPwd')"
          prop="confirmPwd"
          class="confirmPwd"
        >
          <el-input
            v-model="form.confirmPwd"
            maxlength="16"
            :placeholder="$t('initialPwd.placeholderTwo')"
            type="password"
          ></el-input>
          <img
            class="seeArea"
            @click="noSeeClick('confirmPwd')"
            src="../../assets/image/login/visibleIcon.png"
            alt=""
          />
          <img
            class="noSeeArea"
            @click="seeClick('confirmPwd')"
            src="../../assets/image/login/notVisible.png"
            alt=""
          />
        </el-form-item>
        <div>
          <el-button
            disabled
            class="grayButton"
            v-if="form.password == '' || form.confirmPwd == ''"
          >
            {{ $t("initialPwd.determine") }}
          </el-button>
          <el-button
            @click="determineClick('form')"
            class="buttonStatus"
            v-if="form.password != '' && form.confirmPwd != ''"
          >
            {{ $t("initialPwd.determine") }}
          </el-button>
          <el-button @click="resetClick">
            {{ $t("initialPwd.reset") }}
          </el-button>
        </div>
      </el-form>
      <div class="modifyTip">
        <div>
          <img src="../../assets/image/login/successIcon.png" alt="" />
        </div>
        <div>
          {{ $t("initialPwd.tipsOne") }}
          <b> {{ time }} </b>
          {{ $t("initialPwd.tipsTwo") }}
        </div>
        <div @click="loginClick()">{{ $t("initialPwd.login") }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCurrentInfo, initialPsw } from "@/api/api.js";
import { widthLabel } from "@/assets/js/common.js";
export default {
  name: "initialPassword",
  data() {
    return {
      time: 5,
      languageVal: "",
      form: {
        account: "",
        password: "",
        confirmPwd: "",
      },
    };
  },
  computed: {
    labelWidth() {
      var val = widthLabel();
      var width = Number(val.split("px")[0]);
      return this.$i18n.locale == "zh-CN"
        ? `${width + 20}px`
        : `${width + 50}px`;
    },
    formRules() {
      return {
        password: [
          {
            required: true,
            message: this.$t("initialPwd.newTip"),
            trigger: ["blur", "change"],
          },
          {
            min: 6,
            pattern: /^(?=.*\d+)(?=.*[A-Za-z]+)[\w]{6,16}$/,
            required: true,
            message: this.$t("initialPwd.newFormat"),
            trigger: ["blur", "change"],
          },
        ],
        confirmPwd: [
          {
            required: true,
            message: this.$t("initialPwd.passTip"),
            trigger: ["blur", "change"],
          },
        ],
      };
    },
  },
  watch: {
    time(newVal) {
      if (newVal == 0) {
        this.loginClick();
      }
    },
  },
  methods: {
    beforeunloadFn(e) {
      this.time = 5;
    },
    // 语言切换
    langeChange(val) {
      if (localStorage.toolLanguage != val) {
        localStorage.toolLanguage = val;
        this.$i18n.locale = val;
        this.languageVal =
          localStorage.toolLanguage == "zh-CN" ? "中文" : "English";
        this.$store.commit("langSwitch");
      }
    },
    // 密码不可见
    noSeeClick(text) {
      $("." + text + " .seeArea").hide();
      $("." + text + " .noSeeArea").show();
      $("." + text + " .el-input .el-input__inner").attr("type", "password");
    },
    // 密码可见
    seeClick(text) {
      $("." + text + " .seeArea").show();
      $("." + text + " .noSeeArea").hide();
      $("." + text + " .el-input .el-input__inner").attr("type", "text");
    },
    // 获取用户信息
    getUserData() {
      getCurrentInfo().then((res) => {
        if (res.data.code == 100) {
          this.form.account = res.data.data.username;
        } else {
          this.$DonMessage.error(res.data.msg);
        }
      });
    },
    countDown() {
      var _this = this;
      this.time--;
    },
    // 确定
    determineClick(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          var params = new FormData();
          params.append("newPwd", this.form.password);
          params.append("confirmPwd", this.form.confirmPwd);
          initialPsw(params).then((res) => {
            if (res.data.code === 100) {
              this.dialogFormVisible = true;
              this.$DonMessage.success(res.data.msg);
              this.time = 5;
              setInterval(this.countDown, 1000);
              $(".initialform").hide();
              $(".modifyTip").show();
            } else {
              this.$DonMessage.error(res.data.msg);
            }
          });
        }
      });
    },
    loginClick() {
      $(".initialform").show();
      $(".modifyTip").hide();
      // this.$store.commit("del_token");
      this.$router.push("/");
    },
    // 重置
    resetClick() {
      $(".seeArea").hide();
      $(".noSeeArea").show();
      $(".initialDetail .el-input .el-input__inner").attr("type", "password");
      this.form.password = "";
      this.form.confirmPwd = "";
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
  },
  mounted() {
    this.languageVal =
      localStorage.toolLanguage == "zh-CN" ? "中文" : "English";
    this.getUserData();
  },
  created() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadFn(e));
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadFn(e));
  },
};
</script>
<style>
.initialContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: url("../../assets/image/login/initialPawBg.png") no-repeat;
  background-position: center;
  background-size: cover;
}
.initialCenter {
  width: 800px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}
.initialCenter .title {
  font-size: 28px;
  padding: 4% 0;
  margin: 0 2%;
  text-align: center;
  border-bottom: 1px solid #f5f5f5;
}
.initialCenter .initialform {
  margin: 4% 15% 4% 8%;
}
.initialCenter .initialform .el-form-item {
  margin-bottom: 40px !important;
}
.initialCenter .el-form-item__label {
  font-size: 18px;
  font-weight: bold;
  line-height: 44px !important;
}
.initialform .el-input .el-input__inner {
  padding: 0 30px 0 12px !important;
  border: 1px solid #ccc !important;
}
.initialform .el-input .el-input__inner,
.initialform .el-form-item__content {
  height: 44px !important;
  line-height: 44px !important;
  font-size: 16px;
}
.initialform > div:last-child {
  text-align: center;
  margin: 8% 0 8% 7%;
}
.initialCenter .el-button {
  color: #cccccc !important;
  border: 1px solid #cccccc !important;
  padding: 14px 35px;
  font-size: 17px;
  margin: 0 15px;
  background: transparent !important;
}
.initialCenter .el-button.grayButton {
  border: 1px solid #f5f5f5 !important;
  background: #f5f5f5 !important;
  color: #cccccc !important;
}
.initialCenter .el-button.buttonStatus {
  color: #fff !important;
  background: #238beb !important;
  border: 1px solid #238beb !important;
}
.password img,
.confirmPwd img {
  position: absolute;
  width: 22px;
  top: 50%;
  right: 5px;
  transform: translate(0, -50%);
  cursor: pointer;
}
.password .seeArea,
.confirmPwd .seeArea {
  display: none;
}
.initialCenter .modifyTip {
  text-align: center;
  font-size: 15px;
  display: none;
}
.initialCenter .modifyTip > div {
  margin: 15px 0;
}
.initialCenter .modifyTip > div img {
  width: 80%;
}
.initialCenter .modifyTip > div:last-child {
  color: #238beb;
  text-decoration: underline;
  cursor: pointer;
  margin: 25px 0;
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
  .initialCenter {
    width: 720px;
  }
  .initialCenter .title {
    font-size: 26px;
  }
  .initialCenter .initialform {
    margin: 4% 15% 4% 6%;
  }
  .initialCenter .initialform .el-form-item {
    margin-bottom: 35px !important;
  }
  .initialCenter .el-form-item__label {
    font-size: 16px;
    line-height: 40px !important;
  }
  .initialform .el-input .el-input__inner,
  .initialform .el-form-item__content {
    height: 40px !important;
    line-height: 40px !important;
    font-size: 15px;
  }
  .initialCenter .el-button {
    padding: 12px 30px;
    font-size: 16px;
    margin: 0 12px;
  }
  .password img,
  .confirmPwd img {
    width: 20px;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1366px) {
  .initialCenter {
    width: 650px;
  }
  .initialCenter .title {
    font-size: 24px;
  }
  .initialCenter .initialform {
    margin: 4% 15% 4% 5%;
  }
  .initialCenter .initialform .el-form-item {
    margin-bottom: 32px !important;
  }
  .initialCenter .el-form-item__label {
    font-size: 16px;
    line-height: 38px !important;
  }
  .initialform .el-input .el-input__inner,
  .initialform .el-form-item__content {
    height: 38px !important;
    line-height: 38px !important;
    font-size: 14px;
  }
  .initialCenter .el-button {
    padding: 12px 28px;
    font-size: 15px;
    margin: 0 12px;
  }
  .password img,
  .confirmPwd img {
    width: 18px;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1280px) {
  .initialCenter {
    width: 580px;
  }
  .initialCenter .title {
    font-size: 22px;
  }
  .initialCenter .initialform {
    margin: 4% 12% 4% 4%;
  }
  .initialCenter .initialform .el-form-item {
    margin-bottom: 32px !important;
  }
  .initialCenter .el-form-item__label {
    font-size: 16px;
    line-height: 35px !important;
  }
  .initialform .el-input .el-input__inner,
  .initialform .el-form-item__content {
    height: 35px !important;
    line-height: 35px !important;
    font-size: 14px;
  }
  .initialCenter .el-button {
    padding: 10px 26px;
    font-size: 15px;
    margin: 0 10px;
  }
  .password img,
  .confirmPwd img {
    width: 18px;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1024px) {
  .initialCenter {
    width: 550px;
  }
  .initialCenter .title {
    font-size: 20px;
  }
  .initialCenter .initialform {
    margin: 4% 12% 4% 4%;
  }
  .initialCenter .initialform .el-form-item {
    margin-bottom: 30px !important;
  }
  .initialCenter .el-form-item__label {
    font-size: 15px;
    line-height: 32px !important;
  }
  .initialform .el-input .el-input__inner,
  .initialform .el-form-item__content {
    height: 32px !important;
    line-height: 32px !important;
    font-size: 13px;
  }
  .initialCenter .el-button {
    padding: 9px 25px;
    font-size: 14px;
    margin: 0 10px;
  }
  .password img,
  .confirmPwd img {
    width: 18px;
  }
}
@media screen and (max-width: 1024px) {
  .initialCenter {
    width: 550px;
  }
  .initialCenter .title {
    font-size: 20px;
  }
  .initialCenter .initialform {
    margin: 4% 12% 4% 4%;
  }
  .initialCenter .initialform .el-form-item {
    margin-bottom: 30px !important;
  }
  .initialCenter .el-form-item__label {
    font-size: 15px;
    line-height: 33px !important;
  }
  .initialform .el-input .el-input__inner,
  .initialform .el-form-item__content {
    height: 33px !important;
    line-height: 33px !important;
    font-size: 13px;
  }
  .initialCenter .el-button {
    padding: 9px 25px;
    font-size: 14px;
    margin: 0 10px;
  }
  .password img,
  .confirmPwd img {
    width: 18px;
  }
}
</style>
