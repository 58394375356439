import { sysServerUrl } from "@/assets/js/common.js";
import { post, get } from "./common.js";
import axios from "axios";
// 登录
export const login = (params) => post(sysServerUrl + "sys/user/login", params); // 用户登录-获取token
export const logout = (params) =>
  post(sysServerUrl + "sys/user/logout", params); // 退出登录
export const captCha = (params) =>
  get(sysServerUrl + "sys/captcha/get", params); // 用户登录-获取验证码

export const requestTokenRefresh = (params) =>
  axios.create()({
    // 刷新token
    url: sysServerUrl + "sys/user/refresh",
    method: "post",
    data: params,
  });
// 用户管理
export const getCurrentInfo = (params) =>
  get(sysServerUrl + "sys/user/getCurrentInfo", params); // 获取当前账户信息
export const userUpdate = (params) =>
  post(sysServerUrl + "sys/user/updateInfo", params); // 用户自定义修改基本信息
export const updatePsw = (params) =>
  post(sysServerUrl + "sys/user/updatePsw", params); // 用户自定义密码
export const initialPsw = (params) =>
  post(sysServerUrl + "sys/user/initialPsw", params); // 用户管理-修改初始密码

// 专项工具
export const getToolData = (params) =>
  get(sysServerUrl + "release/tool/front/tree", params); // 专项工具-【前台】结构树
// 专项工具-【前台】工具类型
export const getToolType = (params) =>
  get(sysServerUrl + "release/tool/front/type", params); // 专项工具-【前台】工具类型
