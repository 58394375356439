import store from "@/store/index";
import { toolPointLight, sparePartPoint, addTabs, urlHref } from "./common.js";
import router from "@/router";
import axios from "axios";
import i18n from "@/i18n/i18n";
//热点g标签Id
export let hotLabelId = "Label_Layer";
export let hotPointId = "Hotspot";
//热点位置集合索引
export let hotLocationX = 4;
export let hotLocationY = 5;

export let hotReduceY = 3;

//热点圆圈半径
export let hotRadius = "9";
export let moveStatus = "";
export let toolList = [];
export function toolMenuList(list) {
  toolList = list;
}
export function loadSvg(svgPath) {
  $("#mysvg").svg("destroy");
  $("#mysvg").svg({
    loadURL: svgPath,
    onLoad: function (svg) {
      onloadsvg("mysvg");
    },
  });
}

function initHotpoint(id) {
  let find = $(`#${id}`).find("#AUTOID_001").children();
  for (let i = 0; i < find.length; i++) {
    let aNode = $(find[i]);
    aNode.removeAttr("xlink:href");
    aNode.removeAttr("href");
    let attr = aNode.attr("name");
    let number = String(
      attr && attr.indexOf("*") !== -1 ? attr.substring(1) : i + 1
    );
    aNode.attr("index", number).css({
      cursor: "pointer",
    });
    aNode.click(function () {
      $(this).parent().find("path").attr("fill-opacity", "0");
      $(this)
        .children()
        .attr("fill-opacity", "0.5")
        .attr("fill", "var(--theme-color)");
      lightTable(number);
    });
  }
  //移动热点图层
  const image = document.getElementById("AUTOID_001");
  const svg = document.getElementById("mysvg").firstChild;
  if (image != null) {
    svg.appendChild(image);
  }
}

function lightTable(trIndex) {
  var text = trIndex.replace(".", "-");
  //修改svg 对应的表格列表样式
  $(".trStyle").removeClass("trStyle");
  let $activeRow = $(".lightUp." + text);
  $activeRow.addClass("trStyle");
  var url = window.parent.document.baseURI.split("#")[1];
  sparePartPoint(url, text, "");
  var trHeight = $activeRow.outerHeight(true);
  var table = $(".admin-main").height();
  var RightField = $(".detailField").height();
  let margin = $(".el-row").offset().top;
  let top = $activeRow.offset().top;
  var Range = top - $(".admin-main").offset().top;
  let bottom = margin + $(".el-row").height();
  if (top > margin && top + $activeRow.height() < bottom) {
    return;
  }
  if (table - RightField <= 0) {
    return;
  } else if (table - RightField > 0) {
    if (Range - RightField <= 0) {
      var scrollRange =
        $(".detailField").scrollTop() + (top - $(".detailField").offset().top);
      if (scrollRange < table - RightField) {
        $(".detailField").scrollTop(0);
      } else {
        $(".detailField").scrollTop(scrollRange);
      }
    } else if (Range - RightField > 0) {
      $(".detailField").scrollTop(Range - RightField + trHeight);
    }
  }
}

function onloadsvg(id) {
  //svg放大缩小
  var $parent = $(".carte-svg-multipe");
  var $panzoom = $("#mysvg svg").panzoom({
    $zoomIn: $parent.find(".zoom-in"),
    $zoomOut: $parent.find(".zoom-out"),
    $reset: $parent.find(".recover"),
  });
  $panzoom.parent().on("mousewheel.focal DOMMouseScroll.focal", function (e) {
    e.preventDefault();
    var delta = -e.detail || e.delta || e.originalEvent.wheelDelta;
    var zoomOut = delta ? delta < 0 : e.originalEvent.deltaY > 0;
    $panzoom.panzoom("zoom", zoomOut, {
      animate: false,
      focal: e.originalEvent,
    });
  });

  if (!id) {
    id = "mysvg";
  }
  // 去除title标签
  $("#mysvg").find("title").remove();

  // 福田热点新增
  var length = $("#" + id + "").find("#" + hotPointId).length;
  if (length > 0) {
    hotPoint(id, hotPointId);
  } else {
    initHotpoint(id);
  }
}
function hotPoint(id, layerMarker) {
  var $child = $("#" + id + "")
    .find("#" + layerMarker)
    .children();
  $.each($child, function (index) {
    let code = $(this).attr("data-name") || this.id;
    toolList.forEach((item) => {
      item.children.forEach((itemOne) => {
        if (itemOne.code == code) {
          $(this).attr("title", itemOne.name);
        }
        if (code.indexOf(itemOne.code) != -1) {
          $(this).attr("title", itemOne.name);
        }
        if (itemOne.code.indexOf("/") != -1) {
          if (code.indexOf(itemOne.code.split("/")[0]) != -1) {
            $(this).attr("title", itemOne.name);
          }
        }
      });
    });
    $(this).attr("data-code", code);
    $(this).addClass(code.replace(/[*\\//]/g, "-"));
    $(this).removeAttr("data-name");
  });
  $child.mouseover(function (event) {
    $(this).mousemove(function (event) {
      event = event || window.event;
      var left = $(".infoContainer").offset().left + 10;
      var style = $("#" + id + " svg").css("transform");
      var moveX = 0;
      if (style != "none") {
        moveX = Number(style.split(",")[4]);
      }
      moveStatus = moveX;
      var title = $(this).attr("title");
      $(".association").show();
      $(".association").html(title);
      var height = $(".association").outerHeight(true) / 2;
      var width = $(".association").outerWidth(true) / 2;
      let topVal = "";
      let leftVal = "";
      var headerHeight = $(".headerContent").outerHeight(true);
      var navHeight = $(".tabContent").outerHeight(true);
      var allHeight = $(".fileImg").outerHeight(true);
      var allWidth = $(".fileImg").outerWidth(true) + left - 20;
      if (event.clientY > allHeight) {
        // 向下显示
        topVal = event.clientY - height - headerHeight - navHeight - 15;
      } else {
        // 向上显示
        topVal = event.clientY + height - headerHeight - navHeight - 15;
      }
      if (event.clientX - left > left) {
        if (event.clientX + width > allWidth) {
          leftVal = event.clientX - left - width * 2 + 15;
        } else {
          leftVal = event.clientX - left - width + 10;
        }
      } else {
        leftVal = event.clientX - left + 10;
      }
      $(".association").css({ top: topVal, left: leftVal });
    });
    $(this).mouseout(function () {
      moveStatus = false;
      $(".association").html();
      $(".association").hide();
    });
  });
  $child.css({
    cursor: "pointer",
  });
  // 热点单击回调
  $child.click(function () {
    var svgStyle = $("#" + id + " svg").css("transform");
    var moveVal = "";
    if (svgStyle != "none") {
      moveVal = Number(svgStyle.split(",")[4]);
    }
    console.log(Math.abs(moveStatus), moveStatus);
    var distance = Math.abs(Math.abs(moveStatus) - Math.abs(moveVal));
    if (distance < 5) {
      var className = $(this).attr("data-code");
      var url = window.parent.document.baseURI.split("#")[1];
      var assemblyName = url.split("/")[2];
      var hotText = className.replace("_x26_", "&");
      var toolName = "";
      if (hotText.indexOf("_") != -1) {
        toolName = hotText.split("_")[0];
      } else if (hotText.indexOf("/") != -1) {
        toolName = hotText.split("/")[0];
      } else {
        toolName = hotText;
      }
      toolPointLight(url, className);
      router.push({
        name: "manual",
        params: { assemblyName: assemblyName, toolName: toolName },
      });
      addTabs(router.history.current.path, toolName);
      lightElement(id, className, layerMarker);
    }
  });
}
/**
 * 根据元素class点亮热点
 * @param svgBoxId: svg外层dom元素id
 * @param className：目标元素class名称
 */
function lightElement(svgBoxId, className) {
  // 该目标元素之外的所有热点取消点亮
  $("#" + svgBoxId + "#" + hotLabelId + "  *")
    .not("." + className + " *")
    .removeClass("animate textfill linefill");
  for (var i = 0; i < $("#" + svgBoxId + " ." + className).length; i++) {
    // 目标热点
    var $element = $("#" + svgBoxId + " ." + className).eq(i);
    var $children = $element.children();
    var _length = $children.length;
    // 1、有子元素
    if (_length > 0) {
      // 1.1 若已经点亮
      if ($children.hasClass("animate")) {
        $children.removeClass("animate textfill linefill");
        // 1.2 若未点亮
      } else {
        if (!$children.is("text")) {
          $children.addClass("animate linefill");
        } else {
          for (var j = 0; j < _length; j++) {
            if ($children.eq(j).is("text")) {
              console.log();
            } else {
              $children.eq(j).addClass("animate textfill");
            }
          }
        }
      }
      // 2、无子元素
    } else {
      // 2.1 若已经点亮
      if ($element.hasClass("animate")) {
        $element.removeClass("animate textfill linefill");
        // 2.2 若未点亮
      } else {
        if ($element.is("polygon")) {
          $element.addClass("animate linefill");
        } else {
          $element.addClass("animate textfill linefill");
        }
      }
    }
  }
}

/**
 * 图片加载不出显示默认图
 * @param {Object} obj  图片对象，即$('#id')
 */
//根据id和data-name确认热点元素code,并赋值给其data-code属性和class属性
//添加水印
export function watermark(settings) {
  //debugger;
  //默认设置
  let startX = "";
  let startY = "";
  let alpha = "";
  if (settings != undefined) {
    startX = 20;
    startY = 80;
    alpha = 0.4;
  } else {
    startX = 40;
    startY = 160;
    alpha = 0.5;
  }
  var defaultSettings = {
    watermark_txt: i18n.t("header.brand"),
    watermark_x: startX, //水印起始位置x轴坐标
    watermark_y: startY, //水印起始位置Y轴坐标
    watermark_rows: 5, //水印行数
    watermark_cols: 8, //水印列数
    watermark_x_space: 80, //水印x轴间隔
    watermark_y_space: 30, //水印y轴间隔
    watermark_color: "#ededed", //水印字体颜色
    watermark_alpha: alpha, //水印透明度
    watermark_fontsize: "17px", //水印字体大小
    watermark_font: "微软雅黑", //水印字体
    watermark_width: 110, //水印宽度
    watermark_height: "auto", //水印长度
    watermark_angle: 320, //水印倾斜度数
  };
  //采用配置项替换默认值，作用类似jquery.extend
  if (arguments.length === 1 && typeof arguments[0] === "object") {
    // var src = arguments[0] || {};
    // console.log(src);
    // for (num in src) {
    //   if (src[num] && defaultSettings[num] && src[num] === defaultSettings[num])
    //     continue;
    //   else if (src[num]) defaultSettings[num] = src[num];
    // }
  }

  var oTemp = document.createDocumentFragment();
  var pages_width = "";
  var page_height = "";
  //获取页面最大宽度 获取页面最大高度
  if (settings != undefined) {
    var page = settings.contentDocument.querySelector(".detailPage");
    pages_width = Math.max($(page).width(), $(page).width());
    page_height = Math.max(
      $(page)[0].scrollHeight + 100,
      $(page)[0].offsetHeight + 100
    );
  } else {
    pages_width = Math.max(
      $("#mainContent").width(),
      $("#mainContent").width()
    );
    page_height = Math.max(
      $("#mainContent").height() - 100,
      $("#mainContent").height() - 100
    );
  }

  var countX = Math.ceil(pages_width / 315);
  var cutWidth = pages_width * 0.015;
  var page_width = pages_width - cutWidth;
  //获取页面最大高度
  // var page_height = Math.max(
  //   $("#mainContent").height() - 100,
  //   $("#mainContent").height() - 100
  // );
  var countY = Math.ceil(page_height / 315);
  // var page_height = document.body.scrollHeight + document.body.scrollTop;
  //如果将水印列数设置为0，或水印列数设置过大，超过页面最大宽度，则重新计算水印列数和水印x轴间隔
  if (
    defaultSettings.watermark_cols == 0 ||
    parseInt(
      defaultSettings.watermark_x +
        defaultSettings.watermark_width * defaultSettings.watermark_cols +
        defaultSettings.watermark_x_space * (defaultSettings.watermark_cols - 1)
    ) > page_width
  ) {
    defaultSettings.watermark_cols = parseInt(
      (page_width -
        defaultSettings.watermark_x +
        defaultSettings.watermark_x_space) /
        (defaultSettings.watermark_width + defaultSettings.watermark_x_space)
    );
    defaultSettings.watermark_x_space = parseInt(
      (page_width -
        defaultSettings.watermark_x -
        defaultSettings.watermark_width * defaultSettings.watermark_cols) /
        (defaultSettings.watermark_cols - 1)
    );
  }
  // //如果将水印行数设置为0，或水印行数设置过大，超过页面最大长度，则重新计算水印行数和水印y轴间隔
  if (
    defaultSettings.watermark_rows == 0 ||
    parseInt(
      defaultSettings.watermark_y +
        defaultSettings.watermark_height * defaultSettings.watermark_rows +
        defaultSettings.watermark_y_space * (defaultSettings.watermark_rows - 1)
    ) > page_height
  ) {
    defaultSettings.watermark_rows = parseInt(
      (defaultSettings.watermark_y_space +
        page_height -
        defaultSettings.watermark_y) /
        (defaultSettings.watermark_height + defaultSettings.watermark_y_space)
    );
    defaultSettings.watermark_y_space = parseInt(
      (page_height -
        defaultSettings.watermark_y -
        defaultSettings.watermark_height * defaultSettings.watermark_rows) /
        (defaultSettings.watermark_rows - 1)
    );
  }
  var x;
  var y;
  for (var i = 0; i < countY; i++) {
    //水印的纵轴定位是图片的高度
    y =
      defaultSettings.watermark_y +
      (defaultSettings.watermark_y_space + page_height / countY) * i;
    for (var j = 0; j < countX; j++) {
      //水印的横轴定位是图片的宽度
      let countX = Math.ceil(page_width / 300);
      x =
        defaultSettings.watermark_x +
        (page_width / countX + defaultSettings.watermark_x_space) * j;
      //添加水印的背景图片
      var mask_div = document.createElement("div");
      mask_div.id = "mask_div" + i + j;
      mask_div.className = "mask_div";
      mask_div.appendChild(
        document.createTextNode(defaultSettings.watermark_txt)
      );
      // //设置水印div倾斜显示
      mask_div.style.webkitTransform =
        "rotate(-" + defaultSettings.watermark_angle + "deg)";
      mask_div.style.MozTransform =
        "rotate(-" + defaultSettings.watermark_angle + "deg)";
      mask_div.style.msTransform =
        "rotate(-" + defaultSettings.watermark_angle + "deg)";
      mask_div.style.OTransform =
        "rotate(-" + defaultSettings.watermark_angle + "deg)";
      mask_div.style.transform =
        "rotate(-" + defaultSettings.watermark_angle + "deg)";
      mask_div.style.visibility = "";
      mask_div.style.position = "absolute";
      mask_div.style.left = x + "px";
      mask_div.style.top = y + "px";
      mask_div.style.overflow = "hidden";
      mask_div.style.zIndex = "80";
      mask_div.style.padding = "6px";
      mask_div.style.pointerEvents = "none";
      // pointer-events:none  让水印不遮挡页面的点击事件
      //mask_div.style.border="solid #eee 1px";
      mask_div.style.opacity = defaultSettings.watermark_alpha;
      mask_div.style.fontSize = defaultSettings.watermark_fontsize;
      mask_div.style.fontFamily = defaultSettings.watermark_font;
      mask_div.style.color = defaultSettings.watermark_color;
      mask_div.style.textAlign = "center";
      mask_div.style.width = defaultSettings.watermark_width + "px";
      mask_div.style.height = defaultSettings.watermark_height + "px";
      mask_div.style.display = "block";
      oTemp.appendChild(mask_div);
    }
  }
  let name = "";
  if (store.state.systemState == store.state.onlineState) {
    name = store.state.userName || sessionStorage.userName;
  } else {
    name = "";
  }
  if (settings != undefined) {
    // settings.contentDocument.querySelector(".detailPage").appendChild(oTemp);
    // var mask = settings.contentDocument.querySelectorAll(".mask_div");
    // $(mask).html(
    //   "<img src=" +
    //     require("../image/detail/waterMark.png") +
    //     " style='margin-bottom:0px'><p>" +
    //     i18n.t("header.brand") +
    //     "</p><p>" +
    //     name +
    //     "</p>"
    // );
    // $(mask).children("img").css({ width: "45px" });
    // $(mask).children("p").css({
    //   color: "#dbdbdb",
    //   "font-size": "16px",
    //   "word-break": "break-word",
    // });
  } else {
    // document.body.appendChild(oTemp);
    // $(".mask_div").html(
    //   "<img src=" +
    //     require("../image/detail/waterMark.png") +
    //     " style='margin-bottom:0px'><p style='word-break:break-word;'>" +
    //     i18n.t("header.brand") +
    //     "</p><p style='word-break:break-word;'>" +
    //     name +
    //     "</p>"
    // );
    // $(".mask_div").children("img").css({ width: "45px" });
  }
}
