import Vue from "vue";
import Vuex from "vuex";
import i18n from "@/i18n/i18n";
import { getToolData } from "@/api/api.js";
import { moduleType } from "@/assets/js/common.js";
import axios from "axios";
Vue.use(Vuex);

//创建VueX对象
const store = new Vuex.Store({
  // 共享状态（即变量）
  state: {
    // 0:在线  1：离线
    systemState: "0", // 系统当前状态
    onlineState: "0", // 线上状态
    token: "",
    // 缓存组件页面
    catch_components: [],
    // 当前选中的菜单 - 默认选择首页
    activePath: "index",
    // 菜单项 - 默认包含首页
    navTabList: [],
    homeTab: [
      { path: "/index", label: i18n.t("navTab.tabTitle"), name: "index" },
    ],
    toolData: [], // 专用工具数据
    toolLightList: [], //  专用工具热点缓存
    toolBoxList: [], // 专用工具箱点击缓存
  },
  // 更改vuex的store中状态的唯一方法 - 同步操作
  mutations: {
    // 递归工具数据
    toolLoop(state, list) {
      for (var i = 0; i < list.length; i++) {
        if (list[i].typeList != undefined && list[i].typeList.length != 0) {
          this.commit("toolLoop", list[i].typeList);
        } else {
          if (list[i].typeList == undefined) {
            state.toolData.push(list[i]);
          }
        }
      }
    },
    // 语言切换
    langSwitch(state) {
      document.title = i18n.t("header.title");
      // 首页
      var oldFirstData = state.homeTab;
      state.homeTab = [];
      oldFirstData.some((item, index) => {
        if (item.path == "/home" || item.path == "/index") {
          Vue.set(oldFirstData[index], "label", i18n.t("navTab.tabTitle"));
        }
      });
      // tab标签
      var oldTabData = state.navTabList;
      state.navTabList = [];
      oldTabData.some((item, index) => {
        var val = item.path.split("/");
        var type = val[1];
        var modelId = decodeURI(val[2]);
        var vehicleCode = decodeURI(val[3]);
        var manualType = decodeURI(val[4]);
        var codeVal = decodeURI(val[5]);
        // 帮助手册
        if (item.path == "/helpPage") {
          Vue.set(oldTabData[index], "label", i18n.t("header.helpManual"));
        }
        // 专项工具
        if (type == "detail") {
          if (state.systemState == state.onlineState) {
            getToolData().then((res) => {
              if (res.data.code == 100) {
                res.data.data.forEach((item) => {
                  if (item.children != undefined) {
                    item.children.forEach((itemOne) => {
                      if (itemOne.id == modelId) {
                        Vue.set(
                          oldTabData[index],
                          "label",
                          itemOne.name + " " + i18n.t("toolBox.toolTitle")
                        );
                      }
                    });
                  }
                });
              }
            });
          } else {
            axios
              .get(
                `data/${moduleType.tool}/${localStorage.toolLanguage}/toolMenu.json`
              )
              .then((res) => {
                res.data.forEach((item) => {
                  if (item.children != undefined) {
                    item.children.forEach((itemOne) => {
                      if (itemOne.id == modelId) {
                        Vue.set(
                          oldTabData[index],
                          "label",
                          itemOne.name + " " + i18n.t("toolBox.toolTitle")
                        );
                      }
                    });
                  }
                });
              });
          }
        }
      });
      setTimeout(() => {
        state.homeTab = oldFirstData;
        state.navTabList = oldTabData;
      }, 300);
    },
    add(state, num) {
      state.systemState = num;
    },
    // 修改token，并将token存入localStorage
    set_token(state, token) {
      state.token = token;
      sessionStorage.token = token;
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
      sessionStorage.refreshToken = refreshToken;
    },
    set_userName(state, userName) {
      sessionStorage.userName = userName;
      state.userName = userName;
    },
    del_token(state) {
      state.token = "";
      state.refreshToken = "";
      state.userName = "";
      this.commit("clearVUEX");
      sessionStorage.clear();
      sessionStorage.removeItem("token");
    },
    // 工具热点缓存
    toolLightMenu(state, lightMenu) {
      var lightPath = lightMenu.path;
      // 历史已选中菜单列表
      var oldList = state.toolLightList;
      var result = oldList.some((item, index) => {
        if (item.path == lightPath) {
          Vue.set(oldList, index, {
            path: lightMenu.path,
            val: lightMenu.val,
          });
          return true;
        }
      });
      // 如果不包含该对象，则添加
      if (!result) {
        oldList.push({
          path: lightMenu.path,
          val: lightMenu.val,
        });
      }
      state.toolLightList = oldList;
    },
    // 删除工具热点缓存
    removeToolLight(state, val) {
      let cache = state.toolLightList;
      for (let i = 0; i < cache.length; i++) {
        if (cache[i].path === val) {
          cache.splice(i, 1);
        }
      }
      if (cache.length == 0) {
        state.toolLightList = [];
      }
    },
    // 工具箱点击
    toolBoxLight(state, toolLight) {
      var lightPath = toolLight.path;
      // 历史已选中菜单列表
      var oldList = state.toolBoxList;
      var result = oldList.some((item, index) => {
        if (item.path == lightPath) {
          Vue.set(oldList, index, {
            path: toolLight.path,
            val: toolLight.val,
          });
          return true;
        }
      });
      // 如果不包含该对象，则添加
      if (!result) {
        oldList.push({
          path: toolLight.path,
          val: toolLight.val,
        });
      }
      state.toolBoxList = oldList;
    },
    // 删除工具箱热点缓存
    removeToolBoxLight(state, val) {
      let cache = state.toolBoxList;
      for (let i = 0; i < cache.length; i++) {
        if (cache[i].path === val) {
          cache.splice(i, 1);
        }
      }
      if (cache.length == 0) {
        state.toolBoxList = [];
      }
    },
    //清空vuex数据
    clearVUEX(state) {
      state.catch_components = [];
      state.activePath = "index";
      state.navTabList = [];
      state.toolLightList = [];
      state.toolBoxList = [];
    },
    // 跳转页面执行
    selectMenu(state, submenu) {
      // 首页就是 wellcome   也就是 home
      if (submenu.name === "index" || submenu.name === "home") {
        submenu.path = "/home";
        submenu.name = "home";
      }
      // 当前选中菜单
      var activePath = submenu.name;
      // 历史已选中菜单列表
      var oldTabList = state.navTabList;
      // 将菜单信息添加到navTabList - 添加时判断是否已有该标签
      var result = oldTabList.some((item, index) => {
        if (item.name == activePath) {
          Vue.set(oldTabList, index, {
            path: submenu.path,
            name: submenu.name,
            label: submenu.label,
          });
          return true;
        }
      });
      // 如果不包含该对象，则添加
      if (!result) {
        oldTabList.push({
          path: submenu.path,
          name: submenu.name,
          label: submenu.label,
        });
      }
      // 重新赋值
      state.activePath = activePath;
      state.navTabList = oldTabList;
    },
    // 添加keepalive缓存
    addKeepAliveCache(state, val) {
      // 如果是首页不缓存
      if (val === "index" || val === "home") {
        return;
      }
      // 添加时判断，如果该组件已存在，则不添加
      if (state.catch_components.indexOf(val) === -1) {
        // 不存在，缓存页面
        state.catch_components.push(val);
      }
    },
    // 删除keepalive缓存
    removeKeepAliveCache(state, val) {
      let cache = state.catch_components;
      for (let i = 0; i < cache.length; i++) {
        if (cache[i] === val) {
          cache.splice(i, 1);
        }
      }
      state.catch_components = cache;
    },
    //关闭菜单
    closeTab(state, val) {
      // 重新赋值
      state.activePath = val.activePath;
      state.navTabList = val.navTabList;
    },
    // 点击标签选择菜单
    changeMenu(state, val) {
      if (val === "index" || val === "home") {
        state.activePath = "index";
      } else {
        state.activePath = val;
      }
    },
  },
  // 和mutations类似，异步操作
  Action: {},
});

export default store;
