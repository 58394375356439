import axios from "axios";

export function post(url, parameter) {
  return axios({
    url: url,
    method: "post",
    data: parameter,
  });
}

export function get(url, parameter) {
  return axios({
    url: url,
    method: "get",
    params: parameter,
  });
}
export function del(url, parameter) {
  return axios({
    url: url,
    method: "delete",
    params: parameter,
  });
}

export function put(url, parameter) {
  return axios({
    url: url,
    method: "put",
    data: parameter,
  });
}

export function down(url, parameter) {
  return axios({
    url: url,
    responseType: "blob",
    method: "get",
    data: parameter,
  });
}

export function arrayBuffer(url, parameter) {
  return axios({
    url: url,
    method: "get",
    responseType: "arraybuffer",
    params: parameter,
  });
}
