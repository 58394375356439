<template>
  <div class="detailContainer" @click="detailClick()">
    <div class="specificsCenter" @click="specificsClick()">
      <p class="closeIcon" @click="closeClick()">
        <i class="el-icon-close"></i>
      </p>
      <div class="toolBoxDetail" @click="toolBoxClick()">
        <div>
          <div class="topSurface"></div>
          <div class="centerSurface">
            <p><img src="../../assets/image/tool/logo.png" alt="" /></p>
            <div class="depositArea">
              <div
                v-for="(item, index) of toolboxList"
                :key="index"
                :class="'boxDeposit ' + index"
              >
                <div>
                  <div
                    class="toolDeposit"
                    @click="toolDepositClick(item, index, 'click')"
                  >
                    <p>{{ item.name }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottomIconArea">
              <div class="leftBottomIcon">
                <img src="../../assets/image/tool/subIcon.png" alt="" />
              </div>
              <div class="rightBottomIcon">
                <img src="../../assets/image/tool/subIcon.png" alt="" />
              </div>
            </div>
          </div>
          <div class="shadowSurface"></div>
        </div>
      </div>
      <div class="infoContainer" @click="infoSvgClick()">
        <div
          class="carte-svg-multipe"
          id="multipeSvg"
          @mousemove="titleActive($event)"
        >
          <!-- 放大 -->
          <div
            class="iconfont enlarge zoom-in"
            :title="$t('partsDetail.enlarge')"
          >
            <svg-icon icon-class="enlarge"></svg-icon>
          </div>
          <!-- 缩小 -->
          <div
            class="iconfont reduce zoom-out"
            :title="$t('partsDetail.reduce')"
          >
            <svg-icon icon-class="shrink"></svg-icon>
          </div>
          <!-- 还原 -->
          <div
            class="iconfont Reset recover"
            :title="$t('partsDetail.restore')"
          >
            <svg-icon icon-class="restore"></svg-icon>
          </div>
        </div>
        <div class="fileImg">
          <div id="mysvg"></div>
        </div>
        <div class="association"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { loadSvg, toolMenuList } from "@/assets/js/mysvg.js";
import { moduleType, toolBoxOpen, moveTipSvg } from "@/assets/js/common.js";
export default {
  name: "detailInfo",
  data() {
    return {
      toolboxNum: "",
      toolboxList: [],
      assemblyName: "",
      toolName: "",
      boxIndex: 0,
      stateShow: "",
      moduleVal: moduleType.tool,
    };
  },
  watch: {
    $route(to) {
      var _this = this;
      if (to.name == "toolDetail") {
        _this.assemblyName = to.params.detailCode;
        _this.menuList();
      }
    },
    "$i18n.locale"() {
      this.assemblyName = this.$route.params.detailCode;
      this.menuList();
    },
  },
  methods: {
    // 阻止冒泡
    removeStyle() {
      var displayVal = $(".infoContainer").css("display");
      if (displayVal != "none") {
        $(".toolBoxDetail").css({ animation: "outToolBox 0.2s ease-in-out" });
        $(".infoContainer").css({ animation: "outInfoMove 0.2s ease-in-out" });
        this.styleRemove();
        this.$store.commit("removeToolBoxLight", this.$route.path);
      }
    },
    styleRemove() {
      $(".toolBoxDetail,.toolBoxDetail > div").removeAttr("style");
      $(".closeIcon,#multipeSvg").hide();
      setTimeout(() => {
        $(".toolBoxDetail,.infoContainer").removeAttr("style");
        $(".boxDeposit").removeClass("clickState");
        $(".centerSurface").removeClass("surfaceStatus");
        $(".infoContainer").hide();
        this.toobboxDistance();
      }, 160);
    },
    toolBaxShow() {
      $(".infoContainer").hide();
      $(".toolBoxDetail").css({
        animation: "outToolBox 0s ease-in-out",
        transition: "0s ease-in-out",
      });
      $(".infoContainer").css({
        animation: "outInfoMove 0s ease-in-out",
        transition: "0s ease-in-out",
      });
      this.styleRemove();
    },
    detailClick() {
      event.stopPropagation();
      this.removeStyle();
    },
    specificsClick() {
      event.stopPropagation();
    },
    toolBoxClick() {
      event.stopPropagation();
    },
    infoSvgClick() {
      event.stopPropagation();
    },
    // svg数据
    menuList() {
      this.stateShow = "";
      this.$axios
        .get(
          `data/${this.moduleVal}/${this.assemblyName}/${localStorage.toolLanguage}/tool.json`
        )
        .then((res) => {
          var list = res.data;
          var svgData = list.svgList;
          if (svgData.length > 0) {
            this.toolboxList = svgData;
            this.toolboxNum = Math.ceil(this.toolboxList.length / 6);
            this.openState();
            setTimeout(() => {
              if (this.stateShow != true) {
                this.toolBaxShow();
              }
            }, 100);
          }
          toolMenuList(this.toolboxList);
        });
      setTimeout(() => {
        if ($(".infoContainer").css("display") == "none") {
          $(".infoContainer").css({ animation: "infoMove 0s ease-in-out" });
        }
      }, 350);
    },
    detailMenu() {
      var url = `data/${this.moduleVal}/${this.assemblyName}/${localStorage.toolLanguage}/image/${this.toolName}.svg`;
      loadSvg(url);
    },
    closeClick() {
      this.removeStyle();
    },
    // 移动放大缩小等图标
    titleActive(event) {
      moveTipSvg(event);
    },
    fullScreen() {},
    restore() {},
    // 点击
    toolDepositClick(item, index, type) {
      this.toolName = item.name;
      this.boxIndex = index;
      this.detailMenu();
      toolBoxOpen(this.$route.path, item.name);
      $(".centerSurface").addClass("surfaceStatus");
      setTimeout(() => {
        $(".boxDeposit." + index).addClass("clickState");
        if (type != "click") {
          $(".toolBoxDetail .boxDeposit.clickState .toolDeposit").css(
            "transition",
            "0s ease-in-out"
          );
        }
      });
      if (type == "click") {
        if ($(".infoContainer").css("animationName") == "infoMove") {
          $("#mysvg").hide();
          setTimeout(() => {
            $("#mysvg").show();
          }, 140);
        }
      }
      this.boxState(type);
      this.boxStyleState();
    },
    boxState(type) {
      $("#multipeSvg").show();
      $(".infoContainer,.closeIcon").show();
      $(".toolBoxDetail > div").css({ overflow: "hidden" });
      var top = $(".topSurface").height();
      var iconHeight = $(".toolBoxDetail .centerSurface > p").outerHeight(true);
      var boxHeight = $(".boxDeposit").height();
      var svgHeight = $(".specificsCenter").outerHeight(true) * 0.95 - 12;
      var val = top + iconHeight + boxHeight * this.boxIndex;
      $(".toolBoxDetail").css({
        top: svgHeight,
        transform: "translate(-50%, 0)",
        width: "100%",
        marginTop: -val,
      });
      $(".infoContainer").css({
        height: "95%",
        width: "94%",
      });
      if (type == "click") {
        $(".toolBoxDetail").css({
          animation: "toolBoxMove 0.2s ease-in-out",
        });
        $(".infoContainer").css({ animation: "infoMove 0.2s ease-in-out" });
      } else if (type == "default") {
        $(".toolBoxDetail").css({
          animation: "toolBoxMove 0s ease-in-out",
          transition: "0s ease-in-out",
        });
        $(".infoContainer").css({
          animation: "infoMove 0s ease-in-out",
          transition: "0s ease-in-out",
        });
      }
      setTimeout(() => {
        this.toobboxDistance();
      }, 200);
    },
    toolBoxState() {
      var displayVal = $(".infoContainer").css("display");
      if (displayVal != "none") {
        var top = $(".topSurface").height();
        var iconHeight = $(".toolBoxDetail .centerSurface > p").outerHeight(
          true
        );
        var boxHeight = $(".boxDeposit").height();
        var svgHeight = $(".specificsCenter").outerHeight(true) * 0.95 - 12;
        var val = top + iconHeight + boxHeight * this.boxIndex;
        $(".toolBoxDetail").css({
          top: svgHeight,
          transform: "translate(-50%, 0)",
          width: "100%",
          marginTop: -val,
        });
      }
    },
    boxStyleState() {
      var _this = this;
      _this.toolBoxState();
      window.addEventListener("resize", () => {
        _this.toolBoxState();
      });
    },
    openState() {
      var list = this.$store.state.toolBoxList;
      if (list.length > 0) {
        for (var i = 0; i < list.length; i++) {
          for (var j = 0; j < this.toolboxList.length; j++) {
            if (list[i].val == this.toolboxList[j].name) {
              this.toolDepositClick(this.toolboxList[j], j, "default");
              this.stateShow = true;
            }
          }
        }
      }
    },
    // 工具箱
    toolboxSpace() {
      setTimeout(() => {
        if ($(".surfaceStatus").length == 0) {
          var boxWidth = $(".boxDeposit").outerWidth(true);
          var val = boxWidth * 0.04;
          $(".toolBoxDetail .centerSurface > p, .boxDeposit > div").css({
            marginLeft: val,
            marginRight: val,
          });
        } else {
          var openWidth = $(".boxDeposit.clickState").outerWidth(true);
          var marginVal = openWidth * 0.03;
          $(".toolBoxDetail .centerSurface > p, .boxDeposit > div").css({
            marginLeft: marginVal,
            marginRight: marginVal,
          });
        }
      }, 100);
    },
    toobboxDistance() {
      var _this = this;
      _this.toolboxSpace();
      window.addEventListener("resize", () => {
        _this.toolboxSpace();
      });
    },
    // 工具箱最高高度
    toolboxHeight() {
      setTimeout(() => {
        var allHeight = $(".detailContainer").outerHeight(true);
        var topHeight = $(".topSurface").outerHeight(true);
        var iconHeight = $(".centerSurface p").outerHeight(true);
        var footHeight = $(".bottomIconArea").outerHeight(true);
        var shadowHeight = $(".shadowSurface").outerHeight(true);
        var propVal = allHeight * 0.05;
        var val =
          allHeight -
          topHeight -
          iconHeight -
          footHeight -
          shadowHeight -
          propVal;
        $(".depositArea").css("maxHeight", val);
        var boxVal = $(".depositArea").height();
        if (boxVal > val) {
          var length = this.toolboxList.length;
          var boxSize = Math.floor(val / length);
          $(".toolDeposit").css("height", boxSize);
        }
        this.toobboxDistance();
      }, 100);
    },
    toolboxSize() {
      var _this = this;
      _this.toolboxHeight();
      window.addEventListener("resize", () => {
        _this.toolboxHeight();
      });
    },
  },
  mounted() {
    var _this = this;
    _this.assemblyName = _this.$route.params.detailCode;
    _this.menuList();
    _this.toolboxSize();
  },
};
</script>
<style>
.detailContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: url("../../assets/image/tool/bgImage.png") no-repeat;
  background-size: 100% 100%;
}
.specificsCenter {
  width: 70%;
  height: 100%;
  margin: 0 15%;
  position: relative;
}
/* 关闭按钮 */
.specificsCenter .closeIcon {
  position: absolute;
  top: 3px;
  right: -5px;
  z-index: 100;
  background: #000;
  width: 32px;
  height: 32px;
  line-height: 31px !important;
  text-align: center;
  border-radius: 50%;
  display: none;
  cursor: pointer;
}
.specificsCenter .closeIcon .el-icon-close:before {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}
/* 工具箱 */
.toolBoxDetail {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  width: 500px;
}
/* 点击抽屉动画  */
@keyframes toolBoxMove {
  0% {
    width: 500px;
  }
  100% {
    width: 100%;
  }
}
@keyframes outToolBox {
  0% {
    width: 100%;
  }
  100% {
    width: 500px;
  }
}
/* 顶部 */
.toolBoxDetail .topSurface {
  width: 100%;
  height: 20px;
  background: #a53527;
  transform: perspective(7em) rotateX(15deg);
  border: 1px solid rgb(178, 90, 79);
  box-sizing: border-box;
  overflow: hidden;
}
/* 内容 */
.toolBoxDetail .centerSurface {
  width: calc(100% + 12px);
  background: #a53527;
  border-radius: 0 0 1px 1px;
  margin-left: -6px;
  border: 1px solid rgb(176, 78, 65);
  box-sizing: border-box;
  position: relative;
  z-index: 2;
}
/* logo位置 */
.toolBoxDetail .centerSurface > p {
  margin-top: 15px;
  margin-bottom: 15px;
}
.toolBoxDetail .centerSurface > p img {
  width: 70px;
}
/* 抽屉 */
.boxDeposit > div {
  background: #000;
  position: relative;
}
.toolBoxDetail .toolDeposit {
  width: 100%;
  height: 180px;
  border: 2px solid #000;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  margin-top: -4px;
  z-index: 10;
  background: #a53527;
}
/* 抽屉动画 */
.toolBoxDetail .toolDeposit:hover {
  width: calc(100% + 10px);
  border: 1px solid rgb(38, 38, 38);
  transform: translate(-5px, 12px);
  box-sizing: border-box;
  z-index: 100;
  cursor: pointer;
}
/* 展开后抽屉动画 */
.toolBoxDetail .boxDeposit.clickState .toolDeposit,
.toolBoxDetail .boxDeposit.clickState .toolDeposit:hover {
  width: calc(100% + 10px);
  border: 2px solid rgb(38, 38, 38);
  transform: translate(-5px, 25px);
  box-sizing: border-box;
  z-index: 100;
}
.toolBoxDetail .toolDeposit,
.toolBoxDetail .boxDeposit.clickState .toolDeposit,
.toolBoxDetail .boxDeposit.clickState .toolDeposit:hover,
.toolBoxDetail .toolDeposit:hover {
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
/* 把手 标题*/
.toolBoxDetail .toolDeposit p {
  font-size: 12px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  background: url("../../assets/image/tool/icon.png") no-repeat;
  background-size: 100% 100%;
  width: 80%;
  height: 26px;
  line-height: 23px;
  text-align: center;
}
/* 底部螺丝区域*/
.bottomIconArea {
  margin: 16px 4% 6px;
  display: flex;
  justify-content: space-between;
  height: 10px;
  line-height: 10px;
}
.bottomIconArea img {
  width: 9px;
}
/* 底部阴影 */
.toolBoxDetail .shadowSurface {
  width: 100%;
  height: 15px;
  border-radius: 0 0 6px 5px;
  transform: perspective(3em) rotateX(10deg);
  background: rgba(0, 0, 0, 0.7);
  margin: -6px 0 0 0px;
  filter: blur(4px);
  position: relative;
  z-index: 1;
}
/* 展开svg图动画 */
@keyframes infoMove {
  0% {
    width: 400px;
    height: 12%;
    padding-top: 18%;
    transform: perspective(7em) rotateX(4deg);
  }
  100% {
    transform: perspective(7em) rotateX(0deg);
    width: 94%;
    height: 95%;
  }
}
@keyframes outInfoMove {
  0% {
    width: 94%;
    height: 95%;
    transform: perspective(7em) rotateX(0deg);
  }
  100% {
    width: 400px;
    height: 12%;
    padding-top: 18%;
    transform: perspective(7em) rotateX(4deg);
  }
}
/* 展示svg图展示 */
.toolBoxDetail .toolImage {
  display: none;
  width: 100%;
  height: 6px;
  overflow: hidden;
  position: absolute;
  top: 3px;
}
.toolBoxDetail .toolImage img {
  width: 100%;
  margin-top: -33%;
  transform: perspective(20em) rotateX(55deg);
  margin-left: -2px;
}
/* svg内容展开 */
.detailContainer .infoContainer {
  margin-top: 1% !important;
  width: 95%;
  margin: auto;
  height: 95%;
  position: relative;
  display: none;
  overflow: hidden;
}
.detailContainer .fileImg {
  border: 8px solid rgb(139, 47, 35);
  background: #fff;
}
.detailContainer .fileImg,
.detailContainer #mysvg {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
/* 操作图标 */
.infoContainer .carte-svg-multipe {
  background: #5b5b5b;
  position: absolute;
  right: 1%;
  bottom: 1%;
  border-radius: 4px;
  z-index: 2;
  cursor: move;
  box-shadow: 0 0 8px rgb(0 60 108 / 40%);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 10px;
  width: 145px;
  height: 45px;
}
.infoContainer .carte-svg-multipe div {
  margin: 0 12px;
  text-align: center;
}
.infoContainer .carte-svg-multipe div svg {
  width: 24px;
  height: 24px;
  text-align: center;
  vertical-align: middle;
  color: rgb(204, 204, 204) !important;
  cursor: pointer;
}
.infoContainer .carte-svg-multipe div svg:hover {
  color: #f53521 !important;
}
.infoContainer .carte-svg-multipe .restore {
  display: none;
}
.association {
  position: absolute;
  display: none;
  top: 0;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 15px;
  background: #fff;
  color: #000;
  font-size: 15px;
  box-shadow: 0 0 5px 0px #000;
  pointer-events: none;
  white-space: nowrap;
}
@media screen and (max-width: 1600px) and (min-width: 1400px) {
  /* 关闭按钮 */
  .specificsCenter .closeIcon {
    height: 28px;
    width: 28px;
    line-height: 27px !important;
  }
  .specificsCenter .closeIcon .el-icon-close:before {
    font-size: 14px;
  }
  /* 工具箱 */
  .toolBoxDetail {
    width: 400px;
  }
  /* 点击抽屉动画  */
  @keyframes toolBoxMove {
    0% {
      width: 400px;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes outToolBox {
    0% {
      width: 100%;
    }
    100% {
      width: 400px;
    }
  }
  /* 展开svg图动画 */
  @keyframes infoMove {
    0% {
      width: 300px;
      height: 12%;
      padding-top: 17%;
      transform: perspective(7em) rotateX(4deg);
    }
    100% {
      transform: perspective(7em) rotateX(0deg);
      width: 94%;
      height: 95%;
    }
  }
  @keyframes outInfoMove {
    0% {
      width: 94%;
      height: 95%;
      transform: perspective(7em) rotateX(0deg);
    }
    100% {
      width: 300px;
      height: 12%;
      padding-top: 17%;
      transform: perspective(7em) rotateX(4deg);
    }
  }
  /* 顶部 */
  .toolBoxDetail .topSurface {
    height: 18px;
  }
  /* 内容 */
  .toolBoxDetail .centerSurface {
    width: calc(100% + 8px);
    margin-left: -4px;
  }
  /* logo位置 */
  .toolBoxDetail .centerSurface > p {
    margin-top: 9px;
    margin-bottom: 9px;
  }
  .toolBoxDetail .centerSurface > p img {
    width: 65px;
  }
  /* 抽屉 */
  .toolBoxDetail .toolDeposit {
    height: 150px;
    margin-top: -2px;
  }
  /* 抽屉动画 */
  .toolBoxDetail .boxDeposit.clickState .toolDeposit:hover,
  .toolBoxDetail .boxDeposit.clickState .toolDeposit {
    transform: translate(-5px, 22px);
  }
  .toolBoxDetail .toolDeposit:hover {
    transform: translate(-5px, 10px);
  }
  /* 把手 标题*/
  .toolBoxDetail .toolDeposit p {
    height: 24px;
    line-height: 20px;
  }
  /* 底部螺丝区域*/
  .bottomIconArea {
    margin: 12px 4% 5px;
  }
  .centerSurface img {
    width: 9px;
  }
  /* 底部阴影 */
  .toolBoxDetail .shadowSurface {
    width: 100%;
    height: 15px;
  }
  /* 操作图标 */
  .infoContainer .carte-svg-multipe {
    padding: 0px 10px;
    width: 138px;
    height: 40px;
  }
  .infoContainer .carte-svg-multipe div {
    margin: 0 12px;
  }
  .infoContainer .carte-svg-multipe div svg {
    width: 21px;
    height: 21px;
  }
  .infoContainer .association {
    padding: 5px 10px;
    font-size: 14px;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1280px) {
  /* 关闭按钮 */
  .specificsCenter .closeIcon {
    width: 26px;
    height: 26px;
    line-height: 25px !important;
  }
  .specificsCenter .closeIcon .el-icon-close:before {
    font-size: 13px;
  }
  /* 工具箱 */
  .toolBoxDetail {
    width: 350px;
  }
  /* 点击抽屉动画  */
  @keyframes toolBoxMove {
    0% {
      width: 350px;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes outToolBox {
    0% {
      width: 100%;
    }
    100% {
      width: 350px;
    }
  }
  /* 展开svg图动画 */
  @keyframes infoMove {
    0% {
      width: 285px;
      height: 12%;
      padding-top: 17%;
      transform: perspective(7em) rotateX(4deg);
    }
    100% {
      transform: perspective(7em) rotateX(0deg);
      width: 94%;
      height: 95%;
    }
  }
  @keyframes outInfoMove {
    0% {
      width: 94%;
      height: 95%;
      transform: perspective(7em) rotateX(0deg);
    }
    100% {
      width: 285px;
      height: 12%;
      padding-top: 17%;
      transform: perspective(7em) rotateX(4deg);
    }
  }
  /* 顶部 */
  .toolBoxDetail .topSurface {
    height: 18px;
  }
  /* 内容 */
  .toolBoxDetail .centerSurface {
    width: calc(100% + 8px);
    margin-left: -4px;
  }
  /* logo位置 */
  .toolBoxDetail .centerSurface > p {
    /* margin: 7px 4%; */
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .toolBoxDetail .centerSurface > p img {
    width: 55px;
  }
  /* 抽屉 */
  .toolBoxDetail .toolDeposit {
    height: 120px;
    margin-top: -2px;
  }
  /* 展开后抽屉动画 */
  .toolBoxDetail .boxDeposit.clickState .toolDeposit:hover,
  .toolBoxDetail .boxDeposit.clickState .toolDeposit {
    transform: translate(-5px, 25px);
  }
  .toolBoxDetail .toolDeposit:hover {
    transform: translate(-5px, 10px);
  }
  /* 把手 标题*/
  .toolBoxDetail .toolDeposit p {
    height: 24px;
    line-height: 20px;
  }
  /* 底部螺丝区域*/
  .bottomIconArea {
    margin: 12px 4% 5px;
  }
  .centerSurface img {
    width: 8px;
  }
  /* 底部阴影 */
  .toolBoxDetail .shadowSurface {
    width: 100%;
    height: 12px;
    margin: -5px 0 0 0;
    filter: blur(3px);
  }
  /* 操作图标 */
  .infoContainer .carte-svg-multipe {
    padding: 0px 8px;
    width: 115px;
    height: 34px;
  }
  .infoContainer .carte-svg-multipe div {
    margin: 0 10px;
  }
  .infoContainer .carte-svg-multipe div svg {
    width: 19px;
    height: 19px;
  }
  .association {
    padding: 4px 8px;
    font-size: 13px;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1024px) {
  /* 关闭按钮 */
  .specificsCenter .closeIcon {
    width: 23px;
    height: 23px;
    line-height: 22px !important;
  }
  .specificsCenter .closeIcon .el-icon-close:before {
    font-size: 12px;
  }
  /* 工具箱 */
  .toolBoxDetail {
    width: 300px;
  }
  /* 点击抽屉动画  */
  @keyframes toolBoxMove {
    0% {
      width: 300px;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes outToolBox {
    0% {
      width: 100%;
    }
    100% {
      width: 300px;
    }
  }
  /* 展开svg图动画 */
  @keyframes infoMove {
    0% {
      width: 230px;
      height: 12%;
      padding-top: 18%;
      transform: perspective(7em) rotateX(4deg);
    }
    100% {
      width: 94%;
      height: 95%;
      transform: perspective(7em) rotateX(0deg);
    }
  }
  @keyframes outInfoMove {
    0% {
      width: 94%;
      height: 95%;
      transform: perspective(7em) rotateX(0deg);
    }
    100% {
      width: 230px;
      height: 12%;
      padding-top: 18%;
      transform: perspective(7em) rotateX(4deg);
    }
  }
  /* 顶部 */
  .toolBoxDetail .topSurface {
    height: 17px;
  }
  /* 内容 */
  .toolBoxDetail .centerSurface {
    width: calc(100% + 6px);
    margin-left: -3px;
  }
  /* logo位置 */
  .toolBoxDetail .centerSurface > p {
    /* margin: 6px 4%; */
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .toolBoxDetail .centerSurface > p img {
    width: 50px;
  }
  /* 抽屉 */
  .toolBoxDetail .toolDeposit {
    height: 98px;
    margin-top: -2px;
  }
  /* 展开后抽屉动画 */
  .toolBoxDetail .boxDeposit.clickState .toolDeposit:hover,
  .toolBoxDetail .boxDeposit.clickState .toolDeposit {
    transform: translate(-5px, 20px);
  }
  .toolBoxDetail .toolDeposit:hover {
    transform: translate(-5px, 10px);
  }
  /* 把手 标题*/
  .toolBoxDetail .toolDeposit p {
    height: 22px;
    line-height: 18px;
  }
  /* 底部螺丝区域*/
  .bottomIconArea {
    margin: 12px 4% 5px;
  }
  .centerSurface img {
    width: 6px;
  }
  /* 底部 */
  .toolBoxDetail .shadowSurface {
    width: 100%;
    height: 10px;
    margin: -5px 0 0 0px;
    filter: blur(3px);
  }
  /* 操作图标 */
  .infoContainer .carte-svg-multipe {
    padding: 0px 8px;
    width: 100px;
    height: 32px;
  }
  .infoContainer .carte-svg-multipe div {
    margin: 0 8px;
  }
  .infoContainer .carte-svg-multipe div svg {
    width: 18px;
    height: 18px;
  }
  .association {
    padding: 4px 8px;
    font-size: 13px;
  }
}
@media screen and (max-width: 1024px) {
  .specificsCenter .closeIcon {
    width: 23px;
    height: 23px;
    line-height: 22px;
  }
  .specificsCenter .closeIcon .el-icon-close:before {
    font-size: 12px;
  }
  /* 工具箱 */
  .toolBoxDetail {
    width: 300px;
  }
  /* 抽屉动画 */
  @keyframes toolBoxMove {
    0% {
      width: 300px;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes outToolBox {
    0% {
      width: 100%;
    }
    100% {
      width: 300px;
    }
  }
  /* svg内容展开折叠动画 */
  @keyframes infoMove {
    0% {
      width: 215px;
      height: 12%;
      padding-top: 24%;
      transform: perspective(7em) rotateX(4deg);
    }
    100% {
      transform: perspective(7em) rotateX(0deg);
      width: 94%;
      height: 95%;
    }
  }
  @keyframes outInfoMove {
    0% {
      width: 94%;
      height: 95%;
      transform: perspective(7em) rotateX(0deg);
    }
    100% {
      width: 215px;
      height: 12%;
      padding-top: 24%;
      transform: perspective(7em) rotateX(4deg);
    }
  }
  /* 顶部 */
  .toolBoxDetail .topSurface {
    height: 17px;
  }
  /* 内容 */
  .toolBoxDetail .centerSurface {
    width: calc(100% + 6px);
    margin-left: -3px;
  }
  /* logo位置 */
  .toolBoxDetail .centerSurface > p {
    /* margin: 6px 4%; */
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .toolBoxDetail .centerSurface > p img {
    width: 50px;
  }
  /* 抽屉 */
  .toolBoxDetail .toolDeposit {
    height: 95px;
    margin-top: -2px;
  }
  /* 展开后抽屉动画 */
  .toolBoxDetail .boxDeposit.clickState .toolDeposit:hover,
  .toolBoxDetail .boxDeposit.clickState .toolDeposit {
    transform: translate(-5px, 20px);
  }
  .toolBoxDetail .toolDeposit:hover {
    transform: translate(-5px, 10px);
  }
  /* 把手 标题*/
  .toolBoxDetail .toolDeposit p {
    height: 22px;
    line-height: 18px;
  }
  /* 底部螺丝区域*/
  .bottomIconArea {
    margin: 12px 4% 5px;
  }
  .centerSurface img {
    width: 6px;
  }
  /* 底部阴影 */
  .toolBoxDetail .shadowSurface {
    width: 100%;
    height: 10px;
    margin: -5px 0 0 0px;
    filter: blur(3px);
  }
  /* 操作图标 */
  .infoContainer .carte-svg-multipe {
    padding: 0px 8px;
    width: 100px;
    height: 32px;
  }
  .infoContainer .carte-svg-multipe div {
    margin: 0 8px;
  }
  .infoContainer .carte-svg-multipe div svg {
    width: 18px;
    height: 18px;
  }
  .association {
    padding: 4px 8px;
    font-size: 13px;
  }
}
</style>
