<template>
  <el-container class="homeContainer">
    <el-header style="height: auto">
      <common-header></common-header>
      <tabNav></tabNav>
    </el-header>
    <el-main id="mainContent">
      <keep-alive :include="catch_components">
        <router-view> </router-view>
      </keep-alive>
    </el-main>
  </el-container>
</template>
<script>
import tabNav from "@/components/navTabs/navTabs.vue";
import commonHeader from "@/components/common/header.vue";
import { mapState } from "vuex";
import { watermark } from "@/assets/js/mysvg.js";
export default {
  name: "homeContainer",
  components: {
    tabNav,
    commonHeader,
  },
  computed: {
    ...mapState({
      // 从 state 中的到的计算属性
      catch_components: (state) => state.catch_components, // keepalive缓存
    }),
  },
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  methods: {
    themeStyle() {
      var link = document.createElement("link");
      link.type = "text/css";
      link.id = "theme";
      link.rel = "stylesheet";
      link.href = "./static/theme/lightTheme.css";
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    themeSwitch() {
      if (document.getElementById("theme") == null) {
        this.themeStyle();
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    contentArea() {
      var heightAll = $(window).height();
      var headerHeight = $(".el-header").outerHeight(true);
      var centerHeight = heightAll - headerHeight;
      $(".el-main").css("height", centerHeight);
    },
    heightVal() {
      var _this = this;
      _this.contentArea();
      window.addEventListener("resize", function () {
        _this.contentArea();
      });
    },
  },
  mounted() {
    var _this = this;
    _this.themeSwitch();
    _this.heightVal();
    // $(".mask_div").remove();
    // watermark();
  },
  watch: {
    $route(to) {
      // $(".mask_div").remove();
      // watermark();
    },
  },
};
</script>
