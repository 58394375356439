<template>
  <div class="toolContent" v-cloak>
    <div class="toolContainer">
      <div class="searchArea">
        <el-form
          :inline="true"
          :label-width="styleWidth"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('toolBox.factory')" prop="factory">
            <el-select
              filterable
              clearable
              @focus="factoryFocus"
              @change="factoryChange"
              v-model="factory"
              :placeholder="$t('toolBox.factoryTip')"
              ref="factory"
              @visible-change="isShowSelectOption"
            >
              <el-option
                v-for="(item, index) of factoryList"
                :key="index"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('toolBox.assemblyType')" prop="assemblyType">
            <el-select
              filterable
              clearable
              @focus="typeFocus"
              @change="typeChange"
              v-model="assemblyType"
              :placeholder="$t('toolBox.typeTip')"
              ref="assemblyType"
              @visible-change="isShowSelectOption"
            >
              <el-option
                v-for="(item, index) of assemblyTypeList"
                :key="index"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="searchVal">
            <div class="searchRight">
              <el-select
                v-model="searchTypeVal"
                @change="selectChange"
                :style="labelWidth"
                ref="searchType"
                @visible-change="isShowSelectOption"
              >
                <el-option
                  v-for="(item, index) of searchType"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-input
                v-model="searchVal"
                :placeholder="
                  searchTypeVal == 'assembly'
                    ? $t('toolBox.assemblyTip')
                    : $t('toolBox.toolTip')
                "
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="formButton">
              <el-button icon="el-icon-search" @click="searchClick()">
                {{ $t("header.search") }}
              </el-button>
              <el-button
                @click="resetClick()"
                class="resetButton"
                icon="el-icon-refresh-right"
              >
                {{ $t("button.reset") }}
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="contentDetail">
        <div class="navMenu">
          <div
            v-for="(item, index) of menuList"
            :class="index == tabIndex ? 'navActive' : ''"
            :key="index"
          >
            <span @click="typeClick(item, index)">
              {{ item.name }}
            </span>
          </div>
        </div>
        <div class="toolDetail">
          <el-row v-if="toolList.length > 0">
            <el-col
              :xs="8"
              :sm="6"
              :md="4"
              :lg="4"
              v-for="(item, index) of toolList"
              :key="index"
            >
              <div
                :class="'toolInfo ' + tabIndex + '_' + index"
                @click="detailClick(item, index)"
              >
                <div>
                  <img
                    :src="
                      'data/' +
                      moduleVal +
                      '/' +
                      item.id +
                      '/' +
                      $i18n.locale +
                      '/image/' +
                      item.imageName
                    "
                    alt=""
                  />
                </div>
                <p>{{ item.name }}</p>
              </div>
            </el-col>
          </el-row>
          <div v-else class="noData">
            {{ $t("identifying.noData") }}
          </div>
        </div>
        <div class="searchDetail">
          <div class="searchInfo" v-if="searchDetailList.length > 0">
            <div v-for="(item, index) of searchDetailList" :key="index">
              <p>
                <span>{{ index + 1 }}. </span>
                <span class="assemblyText" @click="searchAssembly(item)">{{
                  item.name
                }}</span>
                <b> > </b>
                <span class="partText" @click="searchDetail(item)">
                  <span v-html="item.code"></span>
                  (<span v-html="item.toolName"></span>)
                </span>
              </p>
            </div>
          </div>
          <div v-else class="noData">{{ $t("identifying.noData") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getToolData, getToolType } from "@/api/api.js";
import {
  addTabs,
  moduleType,
  dataStatus,
  fileStatus,
  widthLabel,
  toolLabelVal,
} from "@/assets/js/common.js";
export default {
  name: "toolIndex",
  data() {
    return {
      factory: "",
      assemblyType: "",
      searchVal: "",
      toolDataList: [],
      menuList: [],
      toolList: [],
      tabIndex: 0,
      searchDetailList: [],
      searchTypeVal: "assembly",
      moduleVal: moduleType.tool,
      systemState: "",
      onlineState: "",
      factoryList: [], // 厂家信息
      assemblyTypeList: [], // 总成类型
      searchState: false, // 是否搜索
    };
  },
  computed: {
    searchType() {
      var list = [
        {
          label: this.$t("toolBox.assemblyName"),
          value: "assembly",
        },
        {
          label: this.$t("toolBox.toolName"),
          value: "part",
        },
      ];
      return list;
    },
    styleWidth() {
      var val = widthLabel();
      return val;
    },
    labelWidth() {
      var val = toolLabelVal();
      return val;
    },
  },
  watch: {
    searchVal(val) {
      this.searchVal = val;
      if (this.searchVal == "") {
        this.dataList();
        this.showArea();
        sessionStorage.removeItem("searchText");
      }
    },
    "$i18n.locale"() {
      this.getMethodData();
    },
  },
  methods: {
    // 监听刷新
    beforeunloadFn(e) {
      sessionStorage.removeItem("toolIndex");
      sessionStorage.removeItem("searchType");
      $(".toolInfo").removeClass("toolActive");
      this.removeStorage();
    },
    removeStorage() {
      sessionStorage.removeItem("searchText");
      sessionStorage.removeItem("factoryCode");
      sessionStorage.removeItem("assemblyTypeCode");
    },
    isShowSelectOption(isShowSelectOption) {
      if (!isShowSelectOption) this.$refs.factory.blur();
      if (!isShowSelectOption) this.$refs.assemblyType.blur();
      if (!isShowSelectOption) this.$refs.searchType.blur();
    },
    // 数据
    dataList() {
      var bizCodeVal = "";
      this.$loading.show();
      if (this.searchTypeVal == "assembly") {
        bizCodeVal = this.searchVal;
      }
      if (this.systemState == this.onlineState) {
        var params = {
          bizCode: bizCodeVal,
          factory: this.factory,
          assembly: this.assemblyType,
        };
        getToolData(params).then((res) => {
          if (res.data.code == 100) {
            this.menuList = res.data.data;
            this.generalMethod();
          }
        });
      } else {
        this.$axios
          .get(
            `data/${this.moduleVal}/${localStorage.toolLanguage}/toolMenu.json`
          )
          .then((res) => {
            this.menuList = res.data;
            this.generalMethod();
          });
      }
    },
    generalMethod() {
      this.factoryData();
      this.assemblyTypeData();
      this.toolMenuList();
      this.getSearchState();
      this.$loading.hide();
    },
    // 获取工具展示数据
    getToolData(listArray) {
      let list = listArray[this.tabIndex].children;
      if (list !== undefined) {
        this.toolDataList = list;
        this.toolList = list;
      } else {
        this.toolList = [];
        this.toolDataList = [];
      }
    },
    toolMenuList() {
      if (this.systemState == this.onlineState) {
        this.getToolData(this.menuList);
      } else {
        var listData = this.menuList;
        var arrayList = [];
        if (this.factory == "" && this.assemblyType == "") {
          this.getToolData(this.menuList);
        } else {
          for (var i = 0; i < listData.length; i++) {
            let firstList = {
              code: listData[i].code,
              name: listData[i].name,
              children: [],
            };
            for (var j = 0; j < listData[i].children.length; j++) {
              if (this.factory != "" && this.assemblyType != "") {
                if (
                  listData[i].children[j].factory != null &&
                  listData[i].children[j].assembly != null
                ) {
                  if (
                    listData[i].children[j].factory.code == this.factory &&
                    listData[i].children[j].assembly.code == this.assemblyType
                  ) {
                    let secondList = listData[i].children[j];
                    firstList.children.push(secondList);
                  }
                }
              } else if (this.factory != "") {
                if (listData[i].children[j].factory != null) {
                  if (listData[i].children[j].factory.code == this.factory) {
                    let secondList = listData[i].children[j];
                    firstList.children.push(secondList);
                  }
                }
              } else if (this.assemblyType != "") {
                if (listData[i].children[j].assembly != null) {
                  if (
                    listData[i].children[j].assembly.code == this.assemblyType
                  ) {
                    let secondList = listData[i].children[j];
                    firstList.children.push(secondList);
                  }
                }
              }
            }
            arrayList.push(firstList);
          }
          this.getToolData(arrayList);
        }
      }
    },
    // 厂家数据
    factoryData() {
      this.factoryList = [];
      let factoryArray = [];
      if (this.systemState == this.onlineState) {
        var params = {
          factory: "",
          assembly: this.assemblyType,
          flag: "1",
        };
        getToolType(params).then((res) => {
          this.factoryList = res.data.data;
        });
      } else {
        console.log(this.menuList);
        this.menuList.forEach((item) => {
          item.children.forEach((itemOne) => {
            if (itemOne.factory != null) {
              if (!this.assemblyType) {
                factoryArray.push(itemOne.factory);
              } else {
                if (itemOne.assembly.code == this.assemblyType) {
                  factoryArray.push(itemOne.factory);
                }
              }
            }
          });
        });
        let array = [];
        for (let i = 0; i < factoryArray.length; i++) {
          let item = JSON.stringify(factoryArray[i]);
          if (!array.includes(item)) array.push(item);
        }
        array = array.map((item) => JSON.parse(item));
        this.factoryList = array;
      }
    },
    // 总成类型数据
    assemblyTypeData() {
      this.assemblyTypeList = [];
      let assemblyTypeArray = [];
      if (this.systemState == this.onlineState) {
        var params = {
          factory: this.factory,
          assembly: "",
          flag: "2",
        };
        getToolType(params).then((res) => {
          this.assemblyTypeList = res.data.data;
        });
      } else {
        this.menuList.forEach((item) => {
          item.children.forEach((itemOne) => {
            if (itemOne.assembly != null) {
              if (!this.factory) {
                assemblyTypeArray.push(itemOne.assembly);
              } else {
                if (itemOne.factory.code == this.factory) {
                  assemblyTypeArray.push(itemOne.assembly);
                }
              }
            }
          });
        });
        let array = [];
        for (let i = 0; i < assemblyTypeArray.length; i++) {
          let item = JSON.stringify(assemblyTypeArray[i]);
          if (!array.includes(item)) array.push(item);
        }
        array = array.map((item) => JSON.parse(item));
        this.assemblyTypeList = array;
      }
    },
    // 厂家
    factoryFocus() {
      this.factoryData();
    },
    factoryChange(val) {
      this.factory = val;
      if (val == "") {
        sessionStorage.removeItem("factoryCode");
        this.restoreData();
      }
    },
    // 总成类型
    typeFocus() {
      this.assemblyTypeData();
    },
    typeChange(val) {
      this.assemblyType = val;
      if (val == "") {
        sessionStorage.removeItem("assemblyTypeCode");
        this.restoreData();
      }
    },
    // 搜索值空展示全部数据
    restoreData() {
      if (
        this.factory == "" &&
        this.assemblyType == "" &&
        this.searchVal == ""
      ) {
        this.dataList();
        this.showArea();
      }
    },
    // 总成和工具
    // nav点击
    typeClick(item, index) {
      this.tabIndex = index;
      if (item.children != undefined) {
        this.toolList = item.children;
        this.toolDataList = item.children;
      } else {
        this.toolList = [];
        this.toolDataList = [];
      }
      if (this.toolList.length == 0) {
        this.searchDetailList = [];
      } else {
        if (
          this.factory != "" ||
          this.assemblyType != "" ||
          this.searchVal != ""
        ) {
          this.searchClick();
        }
      }
      sessionStorage.setItem("productType", this.tabIndex);
    },
    // select
    selectChange(val) {
      sessionStorage.setItem("searchType", val);
      this.searchVal = "";
    },
    // 搜索
    keyDown(e) {
      if (e.keyCode === 13) {
        this.searchClick();
      }
    },
    // 是否搜索状态
    getSearchState() {
      if (this.searchState == true) {
        if (
          this.factory != "" ||
          this.assemblyType != "" ||
          this.searchVal != ""
        ) {
          this.getSearchResult();
          this.searchState = false;
        }
      }
    },
    // 点击搜索
    searchClick() {
      if (
        this.factory == "" &&
        this.assemblyType == "" &&
        this.searchVal == ""
      ) {
        this.searchState = false;
        this.dataList();
        this.showArea();
        this.$DonMessage.warning(this.$t("search.searchPlaceholder"));
        this.removeStorage();
      } else {
        sessionStorage.setItem("assemblyTypeCode", this.assemblyType);
        sessionStorage.setItem("factoryCode", this.factory);
        sessionStorage.setItem("searchText", this.searchVal);
        this.searchState = true;
        this.dataList();
      }
    },
    getSearchResult() {
      String.prototype.replaceAll = function (s1, s2) {
        return this.replace(new RegExp(s1, "gm"), s2);
      };
      var arryList = [];
      let regex = new RegExp(
        `${this.searchVal.replace(/[$()-/?[-^{|}]/g, "\\$&")}`,
        "ig"
      );
      if (this.searchVal != "") {
        if (this.searchTypeVal == "assembly") {
          this.toolDataList.forEach((item) => {
            if (item.name.match(regex) !== null) {
              arryList.push(item);
            }
            this.toolList = arryList;
          });
          this.showArea();
        } else {
          this.toolDataList.forEach((item) => {
            var path = `data/${this.moduleVal}/${item.id}/${localStorage.toolLanguage}/tool.json`;
            if (fileStatus(path) != false) {
              this.$axios
                .get(
                  `data/${this.moduleVal}/${item.id}/${localStorage.toolLanguage}/tool.json`
                )
                .then((res) => {
                  res.data.svgList.forEach((itemOne) => {
                    itemOne.children.forEach((itemTwo) => {
                      if (
                        itemTwo.code.match(regex) !== null ||
                        itemTwo.name.match(regex) !== null
                      ) {
                        let typeData = {
                          name: item.name,
                          imageName: item.imageName,
                          svg: itemOne.svg,
                          id: item.id,
                          code: itemTwo.code.replace(
                            regex,
                            "<span class='hightLight'>$&</span>"
                          ),
                          toolName: itemTwo.name.replace(
                            regex,
                            "<span class='hightLight'>$&</span>"
                          ),
                        };
                        arryList.push(typeData);
                      }
                      this.searchDetailList = arryList;
                    });
                  });
                })
                .catch((err) => {
                  return;
                });
            }
          });
          this.hideArea();
        }
      }
    },
    // 重置
    resetClick() {
      this.searchVal = "";
      this.factory = "";
      this.assemblyType = "";
      this.restoreData();
    },
    // 详情
    detailClick(item, index) {
      $(".toolInfo").removeClass("toolActive");
      var path = `data/${this.moduleVal}/${item.id}/${localStorage.toolLanguage}/tool.json`;
      if (dataStatus(path) == false) {
        this.$DonMessage.warning(this.$t("identifying.noData"));
        return;
      }
      this.$axios
        .get(
          `data/${this.moduleVal}/${item.id}/${localStorage.toolLanguage}/tool.json`
        )
        .then((res) => {
          var list = res.data;
          if (list.svgList.length > 0) {
            $(".toolInfo." + this.tabIndex + "_" + index).addClass(
              "toolActive"
            );
            sessionStorage.setItem("toolIndex", index);
            this.$router.push({
              name: "detail",
              params: { detailCode: item.id },
            });
            addTabs(
              this.$route.path,
              list.name + " " + this.$t("toolBox.toolTitle")
            );
          } else {
            this.$DonMessage.warning(this.$t("identifying.noData"));
          }
        })
        .catch((err) => {
          this.$DonMessage.warning(this.$t("identifying.noData"));
        });
    },
    // 搜索跳转详情
    searchAssembly(item) {
      this.$router.push({
        name: "detail",
        params: { detailCode: item.id },
      });
      addTabs(this.$route.path, item.name + this.$t("toolBox.toolTitle"));
    },
    searchDetail(item) {
      var textVal = "";
      var text = item.code.replace(/<\/?[^>]*>/g, "");
      if (text.indexOf("/") != -1) {
        textVal = text.split("/")[0];
      } else {
        textVal = text;
      }
      this.$router.push({
        name: "manual",
        params: { assemblyName: item.id, toolName: textVal },
      });
      addTabs(this.$route.path, text);
    },
    // 高度
    heightSize() {
      setTimeout(() => {
        var allHeight = $(".toolContainer").height();
        var searchHeight = $(".searchArea").outerHeight(true);
        var navHeight = $(".navMenu").outerHeight(true);
        var margin = 0;
        if ($(".toolDetail").css("marginTop") != undefined) {
          margin = Number($(".toolDetail").css("marginTop").split("px")[0]);
        }
        var val = allHeight - searchHeight - navHeight - margin;
        $(".toolDetail,.searchDetail").css("height", val);
      }, 150);
    },
    areaSize() {
      var _this = this;
      _this.heightSize();
      window.addEventListener("resize", () => {
        _this.heightSize();
      });
    },
    showArea() {
      $(".toolDetail").show();
      $(".searchDetail").hide();
    },
    hideArea() {
      $(".toolDetail").hide();
      $(".searchDetail").show();
    },
    // 页面初始化
    pageStatus() {
      this.menuList = [];
      this.toolList = [];
      this.toolDataList = [];
      var productType = sessionStorage.getItem("productType"); // 车辆类型 燃油车、新能源
      var inputVal = sessionStorage.getItem("searchText"); // 总成、工具搜索关键词
      var index = sessionStorage.getItem("toolIndex"); // 点击总成的index
      var searchType = sessionStorage.getItem("searchType"); //搜索类型 总成、工具
      var factoryVal = sessionStorage.getItem("factoryCode"); // 厂家
      var assemblyTypeVal = sessionStorage.getItem("assemblyTypeCode"); // 总成类型
      if (productType != null) {
        this.tabIndex = productType;
      } else {
        this.tabIndex = 0;
      }
      if (searchType != null) {
        this.searchTypeVal = searchType;
      } else {
        this.searchTypeVal = "assembly";
      }
      if (factoryVal != null) {
        this.factory = factoryVal;
      } else {
        this.factory = "";
      }
      if (assemblyTypeVal != null) {
        this.assemblyType = assemblyTypeVal;
      } else {
        this.assemblyType = "";
      }
      if (inputVal != null) {
        this.searchVal = inputVal;
      } else {
        this.searchVal = "";
      }
      if (
        this.factory != "" ||
        this.assemblyType != "" ||
        this.searchVal != ""
      ) {
        this.searchState = true;
      } else {
        this.searchState = false;
      }
      this.dataList();
      if (index != null) {
        var indexNum = Number(index);
        setTimeout(() => {
          $(".toolInfo." + this.tabIndex + "_" + indexNum).addClass(
            "toolActive"
          );
        }, 100);
      } else {
        $(".toolInfo").removeClass("toolActive");
      }
    },
    getMethodData() {
      this.pageStatus();
      this.areaSize();
      window.addEventListener("keydown", this.keyDown);
    },
  },
  mounted() {
    this.systemState = this.$store.state.systemState;
    this.onlineState = this.$store.state.onlineState;
    this.getMethodData();
  },
  deactivated() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadFn(e));
    window.removeEventListener("keydown", this.keyDown, false);
  },
  created() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadFn(e));
  },
};
</script>
<style>
/* 首页 */
.toolContent {
  overflow-x: hidden;
  overflow-y: auto;
  background: var(--theme-bgColor);
  height: 100%;
}
.toolContainer {
  padding: 25px 0 10px;
  height: calc(100% - 35px);
  overflow: hidden;
  color: var(--font-color);
}
/* 搜索框 */
.searchArea {
  padding: 5px 0;
  margin: 0 2.2%;
}
.searchArea .el-form .el-form-item {
  margin-right: 30px;
}
.searchArea .el-input {
  width: 310px;
  /* margin-right: 20px; */
}
/* 类型 */
.searchArea .el-select .el-input {
  width: 100%;
  margin-right: 0;
}
.searchArea .el-select .el-input__inner {
  border-radius: 4px;
  border: 1px solid var(--border-color) !important;
  background: transparent !important;
  color: var(--font-color) !important;
  padding-left: 12px !important;
}
.searchArea .searchRight .el-select .el-input--suffix .el-input__inner {
  border-right: none !important;
  border-radius: 4px 0 0 4px;
}
.searchArea .el-input__inner:focus,
.searchArea .el-input__inner:hover {
  border: 1px solid var(--border-color) !important;
}
/* 输入框 */
.searchArea .el-input__inner {
  border-radius: 0px 4px 4px 0px;
}
/* 内容 */
.contentDetail .navMenu {
  display: flex;
  border-bottom: 1px solid var(--border-color);
  margin: 0 4% 0;
}
.contentDetail .navMenu div {
  padding: 0px 20px 8px;
  margin-right: 3%;
  font-size: 16px;
}
.navActive {
  color: var(--theme-color);
  font-weight: bold;
  border-bottom: 2px solid var(--theme-color);
}
.contentDetail .toolDetail,
.contentDetail .searchDetail {
  margin-left: -32px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 25px;
  padding: 0 4% 0;
}
.contentDetail .searchDetail,
.searchCenter .searchDetail {
  margin-left: 0;
}
.toolDetail .toolInfo {
  background: rgb(247, 249, 250);
  font-size: 18px;
  border-radius: 4px;
  padding: 10px 10px 15px;
  border: 3px solid transparent;
  text-align: center;
  margin: 0 0 30px 30px;
  cursor: pointer;
}
.hightLight {
  color: var(--theme-color);
}
.toolDetail .toolInfo:hover,
.toolDetail .toolInfo.toolActive {
  border: 3px solid var(--theme-color);
}
.toolDetail .toolInfo > div {
  height: 180px;
  line-height: 180px;
  background: #fff;
  border-radius: 2px;
  overflow: hidden;
}
.toolDetail .toolInfo img {
  width: 75%;
  vertical-align: middle;
  margin-top: -1px;
}
.toolDetail .toolInfo p {
  margin: 30px 0 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #000;
}
.noData {
  font-size: 18px;
  margin: 25px 0 30px 30px;
}
.searchCenter,
.contentDetail .searchDetail {
  display: none;
}
.searchDetail .searchInfo {
  font-size: 18px;
}
.searchDetail .searchInfo > div {
  padding: 5px;
  margin-bottom: 8px;
  cursor: pointer;
}
.searchDetail .searchInfo > div > p > b {
  margin: 0 6px;
  font-weight: normal;
}
.searchDetail .searchInfo > div > p > span:first-child {
  color: #777;
  min-width: 30px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}
.assemblyText:hover,
.partText:hover {
  text-decoration: underline;
}
@media screen and (max-width: 1600px) and (min-width: 1400px) {
  /* 首页 */
  .toolContainer {
    padding: 20px 0 8px;
    height: calc(100% - 28px);
  }
  /* 搜索框 */
  .searchArea {
    margin: 0 1.2%;
  }
  .searchArea .el-input {
    width: 270px;
  }
  /* 内容 */
  .contentDetail .toolDetail,
  .contentDetail .searchDetail {
    margin-left: -25px;
    margin-top: 20px;
  }
  .toolDetail .toolInfo {
    font-size: 16px;
    padding: 8px 8px 10px;
    border: 2px solid transparent;
    margin: 0 0 25px 25px;
  }
  .toolDetail .toolInfo:hover,
  .toolDetail .toolInfo.toolActive {
    border: 2px solid var(--theme-color);
  }
  .toolDetail .toolInfo > div {
    height: 155px;
    line-height: 155px;
  }
  .toolDetail .toolInfo img {
    width: 70%;
  }
  .toolDetail .toolInfo p {
    margin: 25px 0 10px;
  }
  .noData {
    font-size: 16px;
    margin: 0 0 30px 25px;
  }
  .searchDetail .searchInfo {
    font-size: 16px;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1280px) {
  /* 首页 */
  .toolContainer {
    padding: 20px 0 8px;
    height: calc(100% - 28px);
  }
  /* 搜索框 */
  .searchArea {
    margin: 0 1.5%;
  }
  .searchArea .el-input {
    width: 210px;
    margin-right: 15px;
  }
  .searchArea .el-select .el-input__inner {
    padding-left: 10px !important;
  }
  /* 内容 */
  .contentDetail .navMenu div {
    padding: 0px 15px 6px;
    font-size: 15px;
  }
  .contentDetail .toolDetail,
  .contentDetail .searchDetail {
    margin-left: -25px;
    margin-top: 20px;
  }
  .toolDetail .toolInfo {
    font-size: 15px;
    padding: 6px 6px 10px;
    border: 2px solid transparent;
    margin: 0 0 25px 25px;
  }
  .toolDetail .toolInfo:hover,
  .toolDetail .toolInfo.toolActive {
    border: 2px solid var(--theme-color);
  }
  .toolDetail .toolInfo > div {
    height: 135px;
    line-height: 135px;
  }
  .toolDetail .toolInfo img {
    width: 72%;
  }
  .toolDetail .toolInfo p {
    margin: 20px 0 5px;
  }
  .noData {
    font-size: 15px;
    margin: 0 0 30px 25px;
  }
  .searchDetail .searchInfo {
    font-size: 15px;
  }
  .searchDetail .searchInfo > div {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1024px) {
  /* 首页 */
  .toolContainer {
    padding: 15px 0 8px;
    height: calc(100% - 23px);
  }
  /* 搜索框 */
  .searchArea {
    margin: 0 1.4%;
  }
  .searchArea .el-input {
    width: 220px;
    margin-right: 15px;
  }
  .searchArea .el-select .el-input__inner {
    padding-left: 8px !important;
  }
  /* 内容 */
  .contentDetail .navMenu div {
    padding: 0px 15px 3px;
    margin-right: 3%;
    font-size: 14px;
  }
  .contentDetail .toolDetail,
  .contentDetail .searchDetail {
    margin-left: -20px;
    margin-top: 20px;
  }
  .toolDetail .toolInfo {
    font-size: 13px;
    padding: 5px 5px 10px;
    border: 2px solid transparent;
    margin: 0 0 20px 20px;
  }
  .toolDetail .toolInfo:hover,
  .toolDetail .toolInfo.toolActive {
    border: 2px solid var(--theme-color);
  }
  .toolDetail .toolInfo > div {
    height: 135px;
    line-height: 135px;
  }
  .toolDetail .toolInfo img {
    width: 72%;
  }
  .toolDetail .toolInfo p {
    margin: 20px 0 5px;
  }
  .noData {
    font-size: 14px;
    margin: 0 0 30px 20px;
  }
  .searchDetail .searchInfo {
    font-size: 14px;
  }
  .searchDetail .searchInfo > div {
    margin-bottom: 4px;
  }
}
@media screen and (max-width: 1024px) {
  /* 首页 */
  .toolContainer {
    padding: 15px 0 8px;
    height: calc(100% - 23px);
  }
  /* 搜索框 */
  .searchArea {
    margin: 0 1.4%;
  }
  .searchArea .el-form .el-form-item {
    margin-right: 20px;
  }
  .searchArea .el-input {
    width: 170px;
  }
  .searchArea .el-select .el-input__inner {
    padding-left: 8px !important;
  }
  /* 内容 */
  .contentDetail .navMenu div {
    padding: 0px 15px 3px;
    margin-right: 3%;
    font-size: 14px;
  }
  .contentDetail .toolDetail,
  .contentDetail .searchDetail {
    margin-left: -15px;
    margin-top: 20px;
  }
  .toolDetail .toolInfo {
    font-size: 13px;
    padding: 5px 5px 10px;
    border: 2px solid transparent;
    margin: 0 0 20px 15px;
  }
  .toolDetail .toolInfo:hover,
  .toolDetail .toolInfo.toolActive {
    border: 2px solid var(--theme-color);
  }
  .toolDetail .toolInfo > div {
    height: 110px;
    line-height: 110px;
  }
  .toolDetail .toolInfo img {
    width: 72%;
  }
  .toolDetail .toolInfo p {
    margin: 20px 0 5px;
  }
  .noData {
    font-size: 14px;
    margin: 0 0 30px 20px;
  }
  .searchDetail .searchInfo {
    font-size: 14px;
  }
  .searchDetail .searchInfo > div {
    margin-bottom: 4px;
  }
}
</style>
