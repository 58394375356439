import store from "@/store/index";
import router from "@/router";
import i18n from "@/i18n/i18n";
import DonMessage from "@/store/message";
export let sysServerUrl = "https://foton.zzrunta.com/tis-server/";
export let backendTisUrl = "/tis-admin/#/dashboard";
export let moduleType = { manual: "manual", tool: "tool", vr: "vr" };
export let brandParams = { brand: "福田" };
export function dataStatus(path) {
  var xmlhttp;
  if (window.XMLHttpRequest) {
    xmlhttp = new XMLHttpRequest();
  } else {
    xmlhttp = new XMLHttpRequest();
    // xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
  }
  xmlhttp.open("GET", path, false);
  xmlhttp.send();
  if (xmlhttp.status !== 200) {
    DonMessage.warning(i18n.t("identifying.noData"));
    return false;
  }
}
export function fileStatus(path) {
  var xmlhttp;
  if (window.XMLHttpRequest) {
    xmlhttp = new XMLHttpRequest();
  } else {
    xmlhttp = new XMLHttpRequest();
    // xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
  }
  xmlhttp.open("GET", path, false);
  xmlhttp.send();
  if (xmlhttp.status !== 200) {
    return false;
  }
}
// 路径跳转
// 详情页跳转
export function urlPush(name, modelId, vehicleCode, manualType, code) {
  router.push({
    name: name,
    params: {
      modelId: modelId,
      vehicleCode: vehicleCode,
      manualType: manualType,
      code: code,
    },
  });
}
// tab添加
export function addTabs(path, title) {
  var name = path.replace("/", "");
  store.commit("addKeepAliveCache", name);
  var submenu = {
    path: path,
    name: name,
    label: title,
  };
  store.commit("selectMenu", submenu);
}
// tab删除
export function removeTabs(path) {
  var pathVal = path.replace("/", "");
  var oldTabList = store.state.navTabList;
  // 删除tabList中的该对象
  for (let i = 0; i < oldTabList.length; i++) {
    var item = oldTabList[i];
    if (item.name === pathVal) {
      oldTabList.splice(i, 1);
    }
  }
  // 删除keepAlive缓存
  store.commit("removeKeepAliveCache", pathVal);
}
// 工具
export function toolPointLight(path, light) {
  var pointMenu = {
    path: path,
    val: light,
  };
  store.commit("toolLightMenu", pointMenu);
}
// 工具箱
export function toolBoxOpen(path, light) {
  var pointMenu = {
    path: path,
    val: light,
  };
  store.commit("toolBoxLight", pointMenu);
}
// 拖拽移动
export function moveTipSvg(event) {
  var svgIcon = document.getElementById("multipeSvg");
  var className = $("#multipeSvg").parent().attr("class");
  var allWidth = $("." + className).outerWidth(true);
  var allHeight = $("." + className).outerHeight(true);
  svgIcon.onmousedown = function (ev) {
    var oevent = ev || event;
    var distanceX = oevent.clientX - svgIcon.offsetLeft;
    var distanceY = oevent.clientY - svgIcon.offsetTop;
    oevent.preventDefault();
    if ($(event.target).attr("class") == "svg-icon") {
      return;
    }
    document.onmousemove = function (ev) {
      var oevent = ev || event;
      oevent.preventDefault();
      if (oevent.clientX - distanceX < 0) {
        svgIcon.style.left = 0 + "px";
      } else if (oevent.clientX - distanceX > allWidth - svgIcon.offsetWidth) {
        svgIcon.style.left = allWidth - svgIcon.offsetWidth + "px";
      } else {
        svgIcon.style.left = oevent.clientX - distanceX + "px";
      }
      if (oevent.clientY - distanceY < 0) {
        svgIcon.style.top = 0 + "px";
      } else if (
        oevent.clientY - distanceY >
        allHeight - svgIcon.offsetHeight
      ) {
        svgIcon.style.top = allHeight - svgIcon.offsetHeight + "px";
      } else {
        svgIcon.style.top = oevent.clientY - distanceY + "px";
      }
    };
    document.onmouseup = function () {
      oevent.preventDefault();
      document.onmousemove = null;
      document.onmouseup = null;
    };
  };
}
export function toolClear() {
  sessionStorage.removeItem("searchText");
  sessionStorage.removeItem("productType");
  sessionStorage.removeItem("toolIndex");
  sessionStorage.removeItem("searchType");
}
export function clearVR() {
  sessionStorage.removeItem("hotVal");
  sessionStorage.removeItem("className");
  sessionStorage.removeItem("listMenu");
  sessionStorage.removeItem("pageNum");
}
export function clearSearch() {
  // window.evaluateState();
  sessionStorage.removeItem("carModel");
  sessionStorage.removeItem("searchVal");
  sessionStorage.removeItem("itemName");
  sessionStorage.removeItem("indexNum");
}
export function widthLabel() {
  var val = document.body.clientWidth;
  if (val <= 1920 && val > 1366) {
    return i18n.locale == "zh-CN" ? "95px" : "135px";
  }
  if (val <= 1366 && val > 1280) {
    return i18n.locale == "zh-CN" ? "82px" : "125px";
  }
  if (val <= 1280 && val > 1024) {
    return i18n.locale == "zh-CN" ? "80px" : "115px";
  }
  if (val <= 1024) {
    return i18n.locale == "zh-CN" ? "80px" : "115px";
  }
}
export function toolLabelVal() {
  var val = document.body.clientWidth;
  if (val <= 1920 && val > 1366) {
    return i18n.locale == "zh-CN" ? "width: 75px" : "width: 115px";
  }
  if (val <= 1366 && val > 1280) {
    return i18n.locale == "zh-CN" ? "width: 70px" : "width: 100px";
  }
  if (val <= 1280 && val > 1024) {
    return i18n.locale == "zh-CN" ? "width: 65px" : "width: 90px";
  }
  if (val <= 1024) {
    return i18n.locale == "zh-CN" ? "width: 65px" : "width: 90px";
  }
}
