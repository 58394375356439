<template>
  <div class="tabContent">
    <div class="tabCenter">
      <div class="homeArea">
        <el-tag
          size="medium"
          v-for="(tab, index) in homeTab"
          :key="tab.name"
          @close="handleClose(tab, index)"
          @click="changeMenu(tab)"
          :closable="tab.name !== 'index'"
          :effect="activePath === tab.name ? 'dark' : 'plain'"
        >
          <span :title="tab.label">{{ tab.label }}</span>
        </el-tag>
      </div>
      <div class="centerTab">
        <div class="tabs-switch-page">
          <el-tag
            size="medium"
            v-for="(tab, index) in navTabList"
            :key="tab.name"
            @close="handleClose(tab, index)"
            @click="changeMenu(tab)"
            :closable="tab.name !== 'index'"
            :effect="activePath === tab.name ? 'dark' : 'plain'"
          >
            <span :title="tab.label" v-if="tab.label.length <= 15">
              {{ tab.label }}
            </span>
            <span :title="tab.label" v-else>
              {{ tab.label.substr(0, 15) }}...
            </span>
          </el-tag>
        </div>
      </div>
      <div class="moreDrop">
        <el-dropdown @visible-change="dropDownShow($event)">
          <span class="dropBut">
            {{ $t("navTab.more") }}
            <i class="el-icon-d-arrow-right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" class="navDropDown">
            <div>
              <el-dropdown-item
                v-for="(tab, index) in navTabList.slice(tabIndex + 1)"
                :key="index"
                @click.native="changeMenu(tab)"
                :effect="activePath === tab.name ? 'dark' : 'plain'"
              >
                <div class="dropdownCenter" :title="tab.label">
                  <div>{{ tab.label }}</div>
                  <div>
                    <i
                      @click="handleClose(tab, index + tabIndex + 1)"
                      class="el-icon-close"
                    ></i>
                  </div>
                </div>
              </el-dropdown-item>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="tabs-close-box">
        <el-dropdown>
          <span>
            <svg-icon icon-class="navClose"></svg-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                @click.native="tabsCloseItem('other')"
                style="white-space: nowrap"
                >{{ $t("navTab.closeOther") }}</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="tabsCloseItem('all')"
                style="white-space: nowrap"
                >{{ $t("navTab.closeAll") }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { moduleType, urlPush, addTabs } from "@/assets/js/common.js";
export default {
  computed: {
    ...mapState({
      // 从 state 中的到的计算属性
      activePath: (state) => state.activePath, // 已选中菜单
      navTabList: (state) => state.navTabList, // tags菜单列表
      homeTab: (state) => state.homeTab,
      tabNum: (state) => state.tabNum,
    }),
  },
  data() {
    return {
      tabSwitchWidth: 0,
      tabMenuList: [],
      tabMoreList: [],
      tabIndex: 0,
    };
  },
  watch: {
    $route(to) {
      this.tagNavStart(to);
      if (this.$store.state.navTabList.length > 0) {
        this.tabSizeArea();
      }
    },
    "$i18n.locale"() {
      var _this = this;
      setTimeout(() => {
        _this.tabSizeArea();
      }, 480);
    },
  },
  methods: {
    // 监听刷新
    beforeunloadFn(e) {
      var _this = this;
      sessionStorage.setItem("upload", $(".moreDrop").outerWidth());
      _this.tabSizeArea();
    },
    // 关闭tab标签
    handleClose(tab, index) {
      event.stopPropagation();
      // 历史选中菜单
      var oldActivePath = this.$store.state.activePath;
      var pathVal = "";
      if (tab.name == "helpPage") {
        sessionStorage.removeItem("helpIframeSrc");
      }
      // 历史已选中菜单列表
      var oldTabList = this.$store.state.navTabList;
      // 计算标签个数
      let length = oldTabList.length - 1;
      // 删除navTabList中的该对象
      for (let i = 0; i < oldTabList.length; i++) {
        var item = oldTabList[i];
        if (item.name === tab.name) {
          oldTabList.splice(i, 1);
        }
      }
      // 删除keepAlive缓存
      this.$store.commit("removeKeepAliveCache", tab.name);
      // 删除工具箱缓存
      this.$store.commit("removeToolBoxLight", tab.path);
      // 删除工具箱热点缓存
      this.$store.commit("removeToolLight", tab.path);
      this.cloaseTabsPosition(oldTabList);
      // 如果关闭的标签不是当前路由的话，就不跳转
      if (tab.name !== oldActivePath) {
        return;
      }
      // 如果length为1，必然只剩下首页标签，此时关闭后，更新到首页
      if (length === 0) {
        // 同时存储菜单
        this.$store.commit("closeTab", {
          activePath: "index",
          navTabList: oldTabList,
        });
        this.$router.push("/index");
        // tab页向左跳转
        // 不再向下执行
        return;
      }
      // 关闭的标签是最右边的话，往左边跳转一个
      if (index === length) {
        // 同时更新路径
        oldActivePath = oldTabList[index - 1].name;
        pathVal = oldTabList[index - 1].path;
        // 同时存储菜单
        this.$store.commit("closeTab", {
          activePath: oldActivePath,
          navTabList: oldTabList,
        });
        let url = decodeURI(decodeURI(pathVal));
        let name = oldTabList[index - 1].name;
        let label = oldTabList[index - 1].label;
        let path = oldTabList[index - 1].path;
        // 向左跳转
        this.changePage(url, name, label, path);
      } else {
        // 同时更新路径
        oldActivePath = oldTabList[index].name;
        pathVal = oldTabList[index].path;
        // 同时存储菜单
        this.$store.commit("closeTab", {
          activePath: oldActivePath,
          navTabList: oldTabList,
        });
        let url = decodeURI(decodeURI(pathVal));
        let name = oldTabList[index].name;
        let label = oldTabList[index].label;
        let path = oldTabList[index].path;
        // 向右跳转
        this.changePage(url, name, label, path);
      }
    },
    // 标签样式
    tagNavStart(item) {
      if (item.path === "/index") {
        this.$store.state.activePath = "index";
      } else {
        this.$store.state.activePath = item.path.replace("/", "");
      }
    },
    // 点击标签跳转路由
    changeMenu(item) {
      event.stopPropagation();
      // 历史选中菜单
      var oldActivePath = this.$store.state.activePath;
      // 首先判断点击的是否是自己，如果是自己则return
      if (oldActivePath === item.name) {
        return;
      }
      // 不是自己，存储菜单
      this.$store.commit("changeMenu", item.name);
      let url = decodeURI(decodeURI(item.path));
      let name = item.name;
      let label = item.label;
      let path = item.path;
      this.changePage(url, name, label, path);
    },
    changePage(url, name, label, path) {
      var moduleVal = moduleType.tool;
      this.$router.push(path);
    },
    // 标签选项卡显示
    tabNavMover(status) {
      var _this = this;
      let dropState = $(".moreDrop").css("display");
      let boxWidth = $(".centerTab").width(); // tab内容长度
      if (sessionStorage.getItem("upload") != null) {
        boxWidth = $(".centerTab").width() - sessionStorage.getItem("upload");
      }
      let tagKey = 0;
      _this.tabIndex = 0;
      _this.tabSwitchWidth = 0;
      setTimeout(() => {
        let navList =
          _this.$store.state.navTabList || $(".tabs-switch-page .el-tag");
        let marginVal = Number($(".el-tag").css("marginRight").split("px")[0]);
        for (var i = 0; i < navList.length; i++) {
          let index = i + 1;
          let tag = $(".tabs-switch-page .el-tag:nth-child(" + index + ")");
          tag.css("display", "inline-block");
          _this.tabSwitchWidth += Number(tag[0].offsetWidth) + marginVal;
          if (boxWidth >= _this.tabSwitchWidth - marginVal) {
            _this.tabIndex = i;
            if (_this.tabIndex >= i) {
              $(".moreDrop").hide();
            } else {
              $(".moreDrop").show();
            }
          } else {
            if (i > _this.tabIndex) {
              tag.hide();
            }
            $(".moreDrop").show();
          }
        }
        sessionStorage.removeItem("upload");
      });
    },
    // 更多
    dropDownShow(show) {
      if (show) {
        $(".dropBut i").css("transform", "rotate(90deg)");
        this.dropHeight();
      } else {
        $(".dropBut i").removeAttr("style");
      }
    },
    // 关闭tab
    closeMethod(list) {
      var _this = this;
      let boxWidth = $(".centerTab").width(); // tab内容长度
      let navList = list;
      setTimeout(() => {
        _this.tabIndex = 0;
        _this.tabSwitchWidth = 0;
        let marginVal = Number($(".el-tag").css("marginRight").split("px")[0]);
        for (var i = 0; i < navList.length; i++) {
          var index = i + 1;
          let tag = $(".tabs-switch-page .el-tag:nth-child(" + index + ")");
          tag.css("display", "inline-block");
          _this.tabSwitchWidth += Number(tag[0].offsetWidth) + marginVal;
          if (boxWidth >= _this.tabSwitchWidth - marginVal) {
            _this.tabIndex = i;
            if (_this.tabIndex >= i) {
              $(".moreDrop").hide();
            } else {
              $(".moreDrop").show();
            }
          } else {
            if (i >= _this.tabIndex) {
              tag.hide();
            }
            $(".moreDrop").show();
          }
        }
      }, 400);
    },
    cloaseTabsPosition(list) {
      var _this = this;
      _this.closeMethod(list);
    },
    // 关闭事件
    tabsCloseItem(item) {
      $(".tabs-switch-page").removeAttr("style");
      sessionStorage.removeItem("leftVal");
      switch (item) {
        case "other":
          var currentPath = this.$store.state.activePath;
          var allList = this.$store.state.navTabList;
          if (allList.length !== 0) {
            if (currentPath == "index") {
              this.$store.commit("clearVUEX");
              this.$router.push("/index");
              sessionStorage.clear();
            } else {
              for (let i = 0; i < allList.length; i++) {
                if (allList[i].name == currentPath) {
                  allList = allList[i];
                }
              }
              if (allList.name != "helpPage") {
                sessionStorage.removeItem("helpIframeSrc");
              }
              var name = allList.path.replace("/", "");
              this.$store.commit("clearVUEX");
              this.tabMenuList = [];
              this.$store.commit("selectMenu", allList);
              this.$store.commit("addKeepAliveCache", name);
              $(".tabs-switch-page .el-tag").css("display", "inline-block");
            }
          }
          break;
        case "all":
          this.$store.commit("clearVUEX");
          this.tabMenuList = [];
          this.$router.push("/index");
          break;
      }
      $(".moreDrop").hide();
    },
    dropHeight() {
      setTimeout(() => {
        var style = $(".moreDrop").css("display");
        if (style != "none") {
          var dark = $(".navDropDown .el-dropdown-menu__item[effect='dark']");
          var liHeight = $(".el-dropdown-menu__item").outerHeight(true) + 20;
          var heightVal = $("#mainContent").height() - 60;
          $(".navDropDown > div").css("maxHeight", heightVal);
          if (dark.length > 0) {
            var top = $(
              ".navDropDown .el-dropdown-menu__item[effect='dark']"
            ).offset().top;
            if (top > heightVal) {
              var scrollVal = top - heightVal + liHeight;
              $(".navDropDown > div").scrollTop(scrollVal);
            } else {
              $(".navDropDown > div").scrollTop(0);
            }
          }
        }
      }, 180);
    },
    tabSizeArea() {
      var _this = this;
      _this.tabNavMover();
      _this.dropHeight();
      window.addEventListener("resize", function () {
        _this.tabNavMover();
        _this.dropHeight();
      });
    },
  },
  mounted() {
    this.tabSizeArea();
  },
  created() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadFn(e));
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadFn(e));
  },
};
</script>
<style lang="scss">
.tabContent {
  height: 40px;
  box-shadow: 0 0 4px 1px var(--shadow-color);
  position: relative;
  background: var(--nav-color);
  z-index: 2;
}
.tabCenter {
  display: flex;
  justify-content: space-between;
}
// 左右按钮
.leftIcon,
.rightIcon,
.tabs-close-box,
.homeArea {
  height: 40px;
  line-height: 40px;
  padding: 0 3px;
  color: var(--text-color);
  background: var(--manual-bgColor);
  box-shadow: 0 0 1px 1px var(--shadow-color);
}
.rightIcon {
  margin-left: 5px;
}
.tabs-close-box,
.homeArea {
  background: transparent;
  box-shadow: none;
  padding: 0 0 0 10px;
}
.tabs-close-box {
  padding: 0 5px;
  margin: 0;
}
.tabs-close-box svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
  vertical-align: middle;
  margin-top: -4px;
  color: var(--font-color);
}
.tabCenter .el-icon-arrow-left,
.tabCenter .el-icon-arrow-right {
  font-size: 18px;
  cursor: pointer;
  vertical-align: middle;
}
// tab
.centerTab {
  flex: 1;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
}
.tabs-switch-page {
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 5px 0 2px;
  width: max-content;
  margin-left: 0;
  line-height: 40px;
}
.tabs-switch-page .el-tag {
  display: none;
}
.el-tag {
  color: var(--tab-textColor) !important;
  cursor: pointer;
  font-size: 16px !important;
  margin-right: 15px;
  padding: 0 10px 0 6px !important;
  background-color: var(--tab-bgColor) !important;
  border: none !important;
  box-shadow: 0 0 2px 1px var(--shadow-color);
}
.el-tag.el-tag--medium.el-tag--dark::before,
.el-tag.el-tag--medium.el-tag--plain::before {
  content: "";
  display: inline-block;
  width: 11px;
  height: 11px;
  background: var(--tips-color);
  border-radius: 50%;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 15px;
  margin-left: 6px;
}
.el-tag.el-tag--medium.el-tag--dark::before {
  background: var(--theme-color);
}
.el-tag--medium {
  height: 30px !important;
  line-height: 30px !important;
}
.el-tag .el-tag__close {
  color: #b2b2b2 !important;
  font-size: 16px !important;
  transform: scale(1);
  font-weight: bold;
  margin-left: 5px;
}
.el-tag .el-tag__close:hover {
  background: transparent !important;
}
// 更多
.moreDrop {
  background: #fff;
  border-left: 1px solid var(--shadow-color);
  border-right: 1px solid var(--shadow-color);
  padding: 0 6px;
  display: none;
  box-sizing: border-box;
}
.moreDrop .el-dropdown {
  height: 100%;
}
.moreDrop .el-dropdown span {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: -2px;
  cursor: pointer;
}
.moreDrop .el-dropdown span i {
  vertical-align: middle;
  margin: 1px 0px 0 2px;
}
.navDropDown > div {
  overflow-x: hidden;
  overflow-y: auto;
}
.navDropDown .el-dropdown-menu__item[effect="dark"] {
  background-color: var(--hover-color) !important;
  color: var(--font-color) !important;
  font-weight: bold;
}
.dropdownCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
}
.dropdownCenter div:first-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
}
.dropdownCenter div i {
  font-size: 12px;
  margin: 0 5px;
}
.el-popper.dropdownCenter[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #fff;
}
@media screen and (max-width: 1600px) and (min-width: 1440px) {
  .tabContent {
    height: 38px;
  }
  .leftIcon,
  .rightIcon,
  .tabs-close-box,
  .homeArea {
    height: 38px;
    line-height: 38px;
  }
  .tabs-close-box svg {
    width: 18px;
    height: 18px;
  }
  .tabCenter .el-icon-arrow-left,
  .tabCenter .el-icon-arrow-right {
    font-size: 15px;
  }
  .el-tag--medium {
    height: 30px !important;
    line-height: 30px !important;
  }
  .tabs-switch-page,
  .homeArea {
    line-height: 38px;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1366px) {
  .tabContent {
    height: 36px;
  }
  .leftIcon,
  .rightIcon,
  .tabs-close-box,
  .homeArea {
    height: 36px;
    line-height: 36px;
  }
  .tabs-close-box svg {
    width: 18px;
    height: 18px;
  }
  .tabCenter .el-icon-arrow-left,
  .tabCenter .el-icon-arrow-right {
    font-size: 15px;
  }
  .el-tag--medium {
    height: 28px !important;
    line-height: 28px !important;
  }
  .tabs-switch-page,
  .homeArea {
    line-height: 36px;
  }
  .el-tag.el-tag--medium.el-tag--dark::before,
  .el-tag.el-tag--medium.el-tag--plain::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-top: -3px;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1280px) {
  .tabContent {
    height: 31px;
  }
  .leftIcon,
  .rightIcon,
  .tabs-close-box,
  .homeArea {
    height: 31px;
    line-height: 31px;
  }
  .tabs-close-box svg {
    width: 16px;
    height: 16px;
  }
  .tabCenter .el-icon-arrow-left,
  .tabCenter .el-icon-arrow-right {
    font-size: 13px;
  }
  .tabs-switch-page,
  .homeArea {
    line-height: 30px;
  }
  .el-tag {
    font-size: 12px !important;
  }
  .el-tag--medium {
    height: 23px !important;
    line-height: 24px !important;
  }
  .el-tag.el-tag--medium.el-tag--dark::before,
  .el-tag.el-tag--medium.el-tag--plain::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    margin-top: -2px;
  }
  .el-tag .el-icon-close {
    top: -2px;
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1024px) {
  .tabContent {
    height: 30px;
  }
  .leftIcon,
  .rightIcon,
  .tabs-close-box,
  .homeArea {
    height: 30px;
    line-height: 30px;
  }
  .leftIcon,
  .rightIcon,
  .tabs-close-box {
    padding: 0 2px;
  }
  .tabs-close-box svg {
    width: 15px;
    height: 15px;
  }
  .tabCenter .el-icon-arrow-left,
  .tabCenter .el-icon-arrow-right {
    font-size: 12px;
  }
  .tabs-switch-page,
  .homeArea {
    line-height: 27px;
  }
  .el-tag {
    font-size: 12px !important;
  }
  .el-tag--medium {
    height: 23px !important;
    line-height: 24px !important;
  }
  .el-tag.el-tag--medium.el-tag--dark::before,
  .el-tag.el-tag--medium.el-tag--plain::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    margin-top: -3px;
  }
  .el-tag .el-icon-close {
    top: -2px;
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1240px) {
  .tabContent {
    height: 30px;
  }
  .leftIcon,
  .rightIcon,
  .tabs-close-box,
  .homeArea {
    height: 30px;
    line-height: 30px;
  }
  .tabs-close-box svg {
    width: 15px;
    height: 15px;
  }
  .tabCenter .el-icon-arrow-left,
  .tabCenter .el-icon-arrow-right {
    font-size: 12px;
  }
  .tabs-switch-page,
  .homeArea {
    line-height: 28px;
  }
  .el-tag {
    font-size: 12px !important;
  }
  .el-tag--medium {
    height: 22px !important;
    line-height: 22px !important;
  }
  .el-tag.el-tag--medium.el-tag--dark::before,
  .el-tag.el-tag--medium.el-tag--plain::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    margin-top: -2px;
  }
  .el-tag .el-icon-close {
    top: -2px;
    font-size: 14px !important;
  }
}
</style>
