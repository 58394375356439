<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>
<script>
export default {
  name: "SvgIcon",
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: "",
    },
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`;
    },
    svgClass() {
      if (this.className) {
        return "svg-icon " + this.className;
      } else {
        return "svg-icon";
      }
    },
    styleExternalIcon() {
      return {
        mask: `url(${this.iconClass}) no-repeat 50% 50%`,
        "-webkit-mask": `url(${this.iconClass}) no-repeat 50% 50%`,
      };
    },
  },
  mounted() {
    document.querySelectorAll("svg title").forEach((res) => {
      res.parentNode.removeChild(res);
    });
  },
};
</script>
<style scoped>
.svg-icon {
  fill: currentColor;
  color: currentColor;
  overflow: hidden;
}
.svg-external-icon {
  background-color: red;
  mask-size: cover !important;
  display: inline-block;
}
</style>
