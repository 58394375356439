import home from "../../views/homePage.vue";
import index from "../../views/index/index.vue";
import detail from "../../views/detail/detailInfo.vue";
import manual from "../../views/manual/manualIndex.vue";
import i18n from "../../i18n/i18n";
const offlineRouter = [
  {
    path: "/home",
    name: "home",
    component: home,
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "index",
        component: index,
      },
      {
        path: "/detail/:detailCode",
        name: "detail",
        component: detail,
      },
      {
        path: "/manual/:assemblyName/:toolName",
        name: "manual",
        component: manual,
      },
    ],
  },
];
export default offlineRouter;
