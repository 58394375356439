import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import axios from "axios";
import Element from "element-ui";
import jquery from "jquery";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/common.css";
import "./assets/css/reset.css";
import "./assets/css/style.css";
import { conversion } from "./filters/filters";
import { autoRefreshToken } from "@/assets/js/refresh_token.js";
import loading from "./plugins/loading/loading.js";
import DonMessage from "./store/message";
import { get, post } from "./api/common";
import i18n from "./i18n/i18n";
import "./assets/icons/index";
Vue.prototype.$ = jquery;
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$axios = axios;
Vue.prototype.$DonMessage = DonMessage;
Element.Dialog.props.closeOnClickModal.default = false;
Vue.use(Element);
Vue.use(loading);
Vue.filter("conversion", conversion);
Vue.config.productionTip = false;
function toLoginPage() {
  clearTimeout(toLoginPage);
  window.isKidOffLineShow = false;
  router.push("/");
}
if (store.state.systemState == store.state.onlineState) {
  axios.interceptors.request.use(
    (config) => {
      // 在发送请求之前做些什么
      //判断是否存在token，如果存在将每个页面header都添加token
      if (sessionStorage.token) {
        config.headers.common["Authorization"] = sessionStorage.token;
      } else {
        router.push({ name: "login" });
      }
      config.headers.common["Accept-Language"] = localStorage.toolLanguage;
      return config;
    },
    (error) => {
      // 对请求错误做些什么
      return Promise.reject(error);
    }
  );

  const broadChannel = new BroadcastChannel("tokenSync");
  window.bc = broadChannel;
  window.bc.onmessage = function (e) {
    let data = JSON.parse(e.data);
    if (store.state.userName && store.state.userName == data.userName) {
      if (
        sessionStorage.token &&
        sessionStorage.token != "" &&
        sessionStorage.refreshToken &&
        sessionStorage.refreshToken != ""
      ) {
        store.commit("set_token", data.token);
        //刷新token不加Bearer
        store.commit("setRefreshToken", data.refreshToken);
        sessionStorage.setItem("store", JSON.stringify(store.state));
      }
    }
  };

  window.bc.onmessageerror = function (e) {
    console.warn("error:", e);
  };

  // function toLoginPage() {
  //   clearTimeout(toLoginPage);
  //   window.isKidOffLineShow = false;
  //   router.push("/");
  // }

  // http response 拦截器
  axios.interceptors.response.use(
    (response) => {
      try {
        if (response.data == "" || response.data == null) {
          DonMessage.error(i18n.t("identifying.errorPrompt"));
          $(".el-loading-parent--relative").removeClass(
            "el-loading-parent--relative"
          );
          $(".el-loading-mask").remove();
          return new Promise((resove) => {});
        }
        if (
          response.headers["content-type"].toLowerCase().indexOf("json") >= 0
        ) {
          if (response.data.code == 555) {
            if (!window.isKidOffLineShow) {
              window.isKidOffLineShow = true;
              DonMessage.error(response.data.msg);
              setTimeout(toLoginPage, 1000);
            }
            return new Promise((resove) => {});
          }
        }
      } catch {
        //不处理
      }

      return response;
    },
    (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            var promise = autoRefreshToken(error.response, axios);
            return promise;
          case 500:
            DonMessage.error(error.response.data.error);
            break;
        }
      }
      if (error.message == undefined) {
        console.log();
      } else {
        DonMessage.error(i18n.t("identifying.errorPrompt"));
      }
      return Promise.reject(error.response.data);
    }
  );
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
