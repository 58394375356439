<template>
  <div
    class="manualContent"
    :element-loading-text="$t('identifying.loadTips')"
    v-loading="loading"
  >
    <div class="centerArea">
      <iframe
        v-show="iframeShow"
        id="iframe"
        :src="this.htmlUrl"
        frameborder="0"
      ></iframe>
    </div>
    <div class="butBack" @click="backTopClick">
      <svg-icon icon-class="top"></svg-icon>
    </div>
  </div>
</template>
<script>
import { moduleType } from "@/assets/js/common.js";
export default {
  name: "manualDetail",
  data() {
    return {
      assemblyName: "",
      toolName: "",
      htmlUrl: "",
      iframeShow: false,
      loading: false,
    };
  },
  watch: {
    $route(to) {
      var _this = this;
      if (to.name == "manual") {
        _this.iframeShow = false;
        _this.assemblyName = _this.$route.params.assemblyName;
        _this.toolName = _this.$route.params.toolName;
        _this.manualDetail();
        _this.heightSize();
      }
    },
    "$i18n.locale"() {
      var _this = this;
      _this.iframeShow = false;
      _this.assemblyName = _this.$route.params.assemblyName;
      _this.toolName = _this.$route.params.toolName;
      _this.manualDetail();
      _this.heightSize();
    },
  },
  methods: {
    // 内容
    manualDetail() {
      this.loading = true;
      var url = `data/${moduleType.tool}/${this.assemblyName}/${localStorage.toolLanguage}/html/${this.toolName}.html`;
      this.htmlUrl = url;
    },
    // 置顶
    backTopClick() {
      const iframe = document.querySelector("#iframe");
      $(iframe.contentWindow.document.body).animate(
        {
          scrollTop: 0,
        },
        100
      );
    },
    backState(height, val) {
      if (height > val) {
        $(".butBack").hide();
      } else {
        $(".butBack").show();
      }
    },
    // 页面样式
    iframeStyle(iframe, height) {
      var _this = this;
      var link = iframe.contentDocument.querySelectorAll("link");
      var theme = getComputedStyle(document.documentElement).getPropertyValue(
        "--theme-color"
      );
      if (theme.indexOf("#2188f5") !== -1) {
        $(link[0]).attr("href", "./css/commons.css");
      } else if (theme.indexOf("#d60212") !== -1) {
        $(link[0]).attr("href", "./css/common.css");
      }
      _this.iframeShow = true;
      _this.loading = false;
      setTimeout(() => {
        var title = iframe.contentDocument.querySelector(".toolDetails");
        var detail = iframe.contentDocument.querySelector(".detailIntroduce");
        var val = $(title)[0].offsetHeight + $(detail)[0].offsetHeight + 30;
        _this.backState(height, val);
      });
    },
    // iframe加载
    iframeStatus() {
      setTimeout(() => {
        var _this = this;
        const iframe = document.querySelector("#iframe");
        let height = $("#iframe").outerHeight(true);
        if (iframe.attachEvent) {
          iframe.attachEvent("onload", function () {
            _this.iframeStyle(iframe, height);
          });
        } else {
          iframe.onload = function () {
            _this.iframeStyle(iframe, height);
          };
        }
      });
    },
    heightSize() {
      var _this = this;
      _this.iframeStatus();
      window.addEventListener("resize", () => {
        _this.iframeStatus();
      });
    },
  },
  mounted() {
    var _this = this;
    _this.iframeShow = false;
    _this.assemblyName = _this.$route.params.assemblyName;
    _this.toolName = _this.$route.params.toolName;
    _this.manualDetail();
    _this.heightSize();
  },
};
</script>
<style>
.manualContent {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: #f5f5f5;
}
.manualContent .centerArea {
  margin: 0 18%;
  padding: 0 2%;
  height: 100%;
  background: #fff;
}
#iframe {
  width: 100%;
  height: 100%;
}
.manualContent .butBack {
  position: absolute;
  bottom: 2px;
  right: 15px;
  display: none;
  cursor: pointer;
}
.manualContent .butBack svg {
  width: 30px;
  height: 30px;
  color: var(--theme-color) !important;
}
@media screen and (max-width: 1280px) and (min-width: 1024px) {
  .manualContent .centerArea {
    margin: 0 16%;
  }
}
@media screen and (max-width: 1024px) {
  .manualContent .centerArea {
    margin: 0 15%;
  }
}
</style>
